import Vue from 'vue'
import VueRouter from 'vue-router'
import Chat from '../components/Chat'

Vue.use(VueRouter)

const routes = [
  {
    name: 'home',
    path: '/',
    component: Chat,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
