<template>
  <div class="pop-up-message"
       :style="{ fontFamily: colors.font }"
  >
    <div class="pop-up-message--content"
         :class="{ text: message.type === 'text'}">
      <TextMessage v-if="message.type === 'text'"
                   :message="message"
                   :messageColors="messageStyles()"
                   :messageStyling="true"
                   @click.native="$emit('openChat')"
                   @remove="$emit('remove')"
      >
        <template v-slot:default="scopedProps">
          <slot name="text-message-body" :message="scopedProps.message" :messageText="scopedProps.messageText" :messageColors="scopedProps.messageColors" :me="scopedProps.me">
          </slot>
        </template>
        <template v-slot:text-message-toolbox="scopedProps">
          <slot name="text-message-toolbox" :message="scopedProps.message" :me="scopedProps.me">
          </slot>
        </template>
      </TextMessage>
      <ImageMessage v-else-if="message.type === 'image'" :data="message.data" />
      <StructureMessage v-else-if="message.type === 'structure'"
                        class="pop-up-message-bubble"
                        :data="message.data"
                        :messageColors="structureMessageStyles()"
                        @buttonPressed="triggerButtonPressed" />
      <div class="pop-up-message--close-button" @click="$emit('close')">
        <icon-cross-small />
      </div>
    </div>

    <Suggestions v-if="message.suggestions"
                 :suggestions="message.suggestions"
                 v-on:sendSuggestion="triggerQuickReplyPressed"
                 :colors="colors"
                 justifyContent="end" />

  </div>
</template>

<script>
import TextMessage from './messages/TextMessage.vue'
import StructureMessage from './messages/StructureMessage.vue'
import ImageMessage from './messages/ImageMessage.vue'
import Suggestions from './Suggestions.vue'
import IconCrossSmall from './icons/icons/IconCrossSmall.vue'

export default {
  components: {
    TextMessage,
    StructureMessage,
    ImageMessage,
    Suggestions,
    IconCrossSmall
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    onButtonPressed: {
      type: Function,
      required: true
    }
  },
  methods: {
    messageStyles (isStruct = false) {
      if (isStruct) {
        return {
          backgroundColor: this.colors.sentMessage.button.bg,
          color: this.colors.sentMessage.text,
          fontSize: this.colors.messageBubble.fontSize,
          fontWeight: this.colors.messageBubble.fontWeight,
          '--button-text': this.colors.sentMessage.button.text,
          '--button-text-hover': this.colors.sentMessage.button.textHover,
          '--button-bg-hover': this.colors.sentMessage.button.bgHover,
          '--button-radius': this.colors.sentMessage.button.radius || '6px'
        }
      }

      return {
        backgroundColor: '#FFF',
        borderRadius: '16px',
        color: '#000',
        fontSize: this.colors.messageBubble.fontSize,
        fontWeight: this.colors.messageBubble.fontWeight,
        lineHeight: this.colors.messageBubble.lineHeight,
        padding: '14px 33px 14px 14px'
      }
    },
    structureMessageStyles () {
      return {
        sent: this.messageStyles(true),
        received: {
          backgroundColor: '#FFF',
          borderRadius: '16px',
          color: '#000',
          fontSize: this.colors.messageBubble.fontSize,
          fontWeight: this.colors.messageBubble.fontWeight,
          lineHeight: this.colors.messageBubble.lineHeight,
          padding: '14px 14px 14px 14px'
        }
      }
    },
    triggerButtonPressed (button) {
      this.onButtonPressed({
        type: 'button',
        data: button
      })
    },
    triggerQuickReplyPressed (quickReply) {
      this.onButtonPressed({
        type: 'quickReply',
        data: quickReply
      })
    }
  }
}
</script>
<style lang="scss">
.pop-up-message {
  max-width: 300px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .pop-up-message--edited{
    opacity: 0.7;
    word-wrap: normal;
    font-size: xx-small;
    text-align: center;
  }
}

.pop-up-message--content {
  background: white;
  display: flex;
  position: relative;
  border-radius: 30px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.text {
  cursor: pointer;
}

.pop-up-message-error {
  margin-top: 2px;
  text-align: right;
  font-size: 70%;
  color: #DD3535;
}

.pop-up-message--meta {
  font-size: xx-small;
  margin-bottom: 0px;
  color: white;
  text-align: center;
}

.pop-up-message-bubble {
  border-radius: 6px;

  p {
    padding-right: 33px;
  }
}

.sc-message--text {
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;

  p {
    margin: 0
  }

  &:hover .sc-message--toolbox{
    left: -20px;
    opacity: 1;
  }
  .sc-message--toolbox{
    transition: left 0.2s ease-out 0s;
    white-space: normal;
    opacity: 0;
    position: absolute;
    left: 0px;
    width: 25px;
    top: 0;
    button {
      background: none;
      border: none;
      padding: 0px;
      margin: 0px;
      outline: none;
      width:100%;
      text-align: center;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
}
.sc-message--text {
  color: white;
  background-color: #4e8cff;
  overflow-wrap: anywhere;
}

.pop-up-message--close-button {
  background: rgba(105, 119, 140, 0.4);
  position: absolute;
  right: 0;
  margin-top: 8px;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background: rgb(255, 70, 70);
  }

  svg {
    width: 8px;
  }
}
</style>
