<template>
  <div class="wrapper" :style="{flexDirection: chatWindowPosition==='left' ? 'row' : 'column'}">
    <pop-up-message v-if="isPopUpOpen"
                   :message="lastMessage"
                   :colors="colors"
                   :onButtonPressed="onButtonPressed"
                   @openChat="open(USER_EVENT.POP_UP_CLICK_OPEN, lastMessage)"
                   @remove="$emit('remove', message)"
                   @close="$emit('closePopUp')"
    >
      <template v-slot:text-message-body="scopedProps">
        <slot name="text-message-body" :message="scopedProps.message" :messageText="scopedProps.messageText" :messageColors="scopedProps.messageColors" :me="scopedProps.me">
        </slot>
      </template>
    </pop-up-message>

    <chat-window class="chat-window"
                 :showLauncher="showLauncher"
                 :showCloseButton="showCloseButton"
                 :messageList="messageList"
                 :onUserInputSubmit="onMessageWasSent"
                 :onButtonPressed="onButtonPressed"
                 :participants="participants"
                 :title="chatWindowTitle"
                 :titleImageUrl="titleImageUrl"
                 :isOpen="isOpen"
                 :onClose="close"
                 :showEmoji="showEmoji"
                 :showFile="showFile"
                 :showVoice="showVoice"
                 :showHeader="showHeader"
                 :placeholder="placeholder"
                 :showTypingIndicator="showTypingIndicator"
                 :colors="colors"
                 :alwaysScrollToBottom="alwaysScrollToBottom"
                 :messageStyling="messageStyling"
                 :disableUserListToggle="disableUserListToggle"
                 :forceFullScreen="forceFullScreen"
                 :hide-launcher="hideLauncher"
                 :powered-by-amio-config="poweredByAmioConfig"
                 @onType="$emit('onType')"
                 @remove="$emit('remove', $event)"
                 :style="{marginBottom: chatWindowPosition==='left' ? '0px' : '18px', marginRight: marginRight}"
    >
      <template v-slot:header>
        <slot name="header">
        </slot>
      </template>
      <template v-slot:user-avatar="scopedProps">
        <slot name="user-avatar" :user="scopedProps.user" :message="scopedProps.message">
        </slot>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <slot name="text-message-body" :message="scopedProps.message" :messageText="scopedProps.messageText"
              :messageColors="scopedProps.messageColors" :me="scopedProps.me">
        </slot>
      </template>
      <template v-slot:system-message-body="scopedProps">
        <slot name="system-message-body" :message="scopedProps.message">
        </slot>
      </template>
      <template v-slot:text-message-toolbox="scopedProps">
        <slot name="text-message-toolbox" :message="scopedProps.message" :me="scopedProps.me">
        </slot>
      </template>
    </chat-window>

    <chat-button v-if="showLauncher"
                 :isOpen="isOpen"
                 :isWide="wideButton"
                 :isPopUpOpen="isPopUpOpen"
                 :newMessagesCount="newMessagesCount"
                 :hide-launcher="hideLauncher"
                 :launcherImageUrl="launcherImageUrl"
                 :colors="colors"/>
  </div>
</template>
<script>
import PopUpMessage from './PopUpMessage'
import ChatWindow from './ChatWindow'
import ChatButton from './ChatButton'

import { EventBus, Event } from '../../util/event-bus'
import USER_EVENT from '../../util/user-event.constants'

export default {
  data () {
    return {
      USER_EVENT
    }
  },
  props: {
    showEmoji: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    open: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    showFile: {
      type: Boolean,
      default: false
    },
    showVoice: {
      type: Boolean,
      default: false
    },
    showLauncher: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    isPopUpOpen: {
      type: Boolean,
      default: false
    },
    participants: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: () => ''
    },
    titleImageUrl: {
      type: String,
      default: () => ''
    },
    launcherImageUrl: {
      type: String,
      default: () => ''
    },
    onMessageWasSent: {
      type: Function,
      required: true
    },
    onButtonPressed: {
      type: Function,
      required: true
    },
    messageList: {
      type: Array,
      default: () => []
    },
    newMessagesCount: {
      type: Number,
      default: () => 0
    },
    placeholder: {
      type: String,
      default: 'Write a message...'
    },
    showTypingIndicator: {
      type: Boolean,
      default: () => false
    },
    wideButton: {
      type: Boolean,
      default: () => false
    },
    colors: {
      type: Object,
      required: false,
      validator: c =>
        'header' in c &&
        'bg' in c.header &&
        'text' in c.header &&
        'launcher' in c &&
        'bg' in c.launcher &&
        'messageList' in c &&
        'bg' in c.messageList &&
        'sentMessage' in c &&
        'bg' in c.sentMessage &&
        'text' in c.sentMessage &&
        'receivedMessage' in c &&
        'bg' in c.receivedMessage &&
        'text' in c.receivedMessage &&
        'userInput' in c &&
        'bg' in c.userInput &&
        'text' in c.userInput,
      default: function () {
        return {
          header: {
            bg: '#4e8cff',
            text: '#ffffff'
          },
          launcher: {
            bg: '#4e8cff'
          },
          messageList: {
            bg: '#ffffff'
          },
          sentMessage: {
            bg: '#4e8cff',
            text: '#ffffff'
          },
          receivedMessage: {
            bg: '#f4f7f9',
            text: '#ffffff'
          },
          userInput: {
            bg: '#f4f7f9',
            text: '#565867'
          }
        }
      }
    },
    alwaysScrollToBottom: {
      type: Boolean,
      default: () => false
    },
    messageStyling: {
      type: Boolean,
      default: () => false
    },
    disableUserListToggle: {
      type: Boolean,
      default: true
    },
    forceFullScreen: {
      type: Boolean,
      default: false
    },
    hideLauncher: {
      type: String,
      default: ''
    },
    poweredByAmioConfig: {
      type: Object,
      default: function () {
        return {
          enabled: true,
          utmSource: 'chatWidget'
        }
      }
    },
    chatWindowPosition: {
      type: String
    }
  },
  computed: {
    chatWindowTitle () {
      if (this.title !== '') {
        return this.title
      }

      if (this.participants.length === 0) {
        return 'You'
      } else if (this.participants.length > 1) {
        return 'You, ' + this.participants[0].name + ' & others'
      } else {
        return 'You & ' + this.participants[0].name
      }
    },
    lastMessage () {
      const index = this.messageList.length - 1
      return this.messageList[index]
    },
    marginRight () {
      if (this.chatWindowPosition === 'left') {
        return '18px'
      }
      const marginRight = this.colors.launcher.margin / 2
      return `${marginRight}px`
    }
  },
  components: {
    PopUpMessage,
    ChatWindow,
    ChatButton
  },
  mounted () {
    EventBus.$on(Event.CHAT_OPENED, () => {
      this.open(USER_EVENT.LAUNCHER_CLICK_OPEN)
    })
    EventBus.$on(Event.CHAT_CLOSED, () => {
      this.close(USER_EVENT.LAUNCHER_CLICK_CLOSE)
    })
  }
}
</script>
<style scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
}

</style>
