<template>
  <svg width="100%" height="100%" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g>
      <path d="M28.228,23.986L47.092,5.122C48.264,3.951 48.264,2.051 47.092,0.88C45.92,-0.292 44.022,-0.292 42.85,0.88L23.986,19.744L5.121,0.88C3.949,-0.292 2.051,-0.292 0.879,0.88C-0.293,2.051 -0.293,3.951 0.879,5.122L19.744,23.986L0.879,42.85C-0.293,44.021 -0.293,45.921 0.879,47.092C1.465,47.677 2.233,47.97 3,47.97C3.767,47.97 4.535,47.677 5.121,47.091L23.986,28.227L42.85,47.091C43.436,47.677 44.204,47.97 44.971,47.97C45.738,47.97 46.506,47.677 47.092,47.091C48.264,45.92 48.264,44.02 47.092,42.849L28.228,23.986Z"
        :fill="fill"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String
    }
  }
}
</script>
