<template>
  <div ref="launcher"
       class="sc-launcher"
       :class="[
         animationClass,
         {
           open: isOpen,
           closed: !isOpen,
           wide: isWideLauncher
         }
       ]"
       @click.prevent="isOpen ? close() : open()"
       :style="launcherStyle"
  >
    <div v-if="newMessagesCount > 0 && !isOpen" class="sc-new-messsages-count"
         :style="{background: colors.launcher.newMessagesCountBg || '#ff4646'}">
      {{ newMessagesCount }}
    </div>

    <icon-cross v-if="isOpen"
                class="sc-closed-icon"
                :fill="colors.launcher.closeIconColor || '#fff'"
    />

    <div :class="{ wide: isWideLauncher }" class="sc-icon-wrapper">
      <img v-show="!isOpen"
           ref="buttonIcon"
           class="sc-open-icon"
           :src="launcherImageUrl || icons.open.img"
           :alt="icons.open.name"
      />

      <p v-if="isWideLauncher"
         ref="buttonTitle"
         class="sc-button-text"
         :style="{
           '--margin-bottom': colors.launcher.marginBottom || 'auto',
           'font-weight': colors.launcher.fontWeight || 600
         }"
      >
        {{ $t('buttonTitle') }}
      </p>
    </div>
  </div>
</template>

<script>
import OpenIcon from './assets/logo-no-bg.svg'
import IconCross from './icons/icons/IconCross'
import { EventBus, Event } from '../../util/event-bus'
import { ANIMATION_TIME } from '@/util/constants'
import { sleep } from '@/util/mixins'

export default {
  components: { IconCross },
  props: {
    newMessagesCount: {
      type: Number,
      default: () => 0
    },
    colors: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    isWide: {
      type: Boolean,
      default: false
    },
    isPopUpOpen: {
      type: Boolean,
      default: false
    },
    hideLauncher: {
      type: String,
      default: ''
    },
    launcherImageUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      icons: {
        open: {
          img: OpenIcon,
          name: 'open'
        }
      },
      animationClass: '',
      buttonDimensions: {}
    }
  },
  async mounted () {
    await this.$nextTick()
    if (this.colors.launcher.animation) {
      this.animationClass = 'heartbeat'
    } else {
      this.animationClass = 'scale-in-center'
    }
    this.setButtonDimensions()
  },
  methods: {
    async open () {
      if (this.hideLauncherOnChatOpen) await this.resolveAnimation()
      EventBus.$emit(Event.CHAT_OPENED)
    },
    close () {
      EventBus.$emit(Event.CHAT_CLOSED)
    },
    async resolveAnimation () {
      this.animationClass = 'scale-out-center'
      await sleep(ANIMATION_TIME)
    },

    setButtonDimensions () {
      const iconProps = getComputedStyle(this.$refs.buttonIcon)
      const buttonIconHeight = parseFloat(iconProps.height)
      const buttonIconWidth = parseFloat(iconProps.width)
      const buttonShadow = this.colors.launcher.margin || 0
      const buttonWidth = this.getButtonWidth(buttonIconWidth, buttonShadow)
      const buttonHeight = `${buttonIconHeight + buttonShadow}px`
      EventBus.$emit(Event.LAUNCHER_DIMENSIONS, buttonWidth, buttonHeight)
    },

    getButtonWidth (buttonIconWidth, buttonShadow) {
      if (!this.isWideLauncher) {
        return `${buttonIconWidth + buttonShadow}px`
      }
      const font = getComputedStyle(this.$refs.launcher).font
      const buttonTitleWidth = this.getTitleWidth(this.$t('buttonTitle'), font)
      this.buttonDimensions = { buttonIconWidth, buttonTitleWidth }
      const wrapperWidth = this.buttonDimensions.buttonTitleWidth + buttonIconWidth
      return `${wrapperWidth + buttonShadow}px`
    },

    getTitleWidth (text, font) {
      // this method doesn't work with spaces, or special characters: replacing ?, ; and ' ' with 'd'
      const sanitizedText = text.replace(/[?;\s]/g, 'd')
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = font
      let titleWidth = context.measureText(sanitizedText).width
      if (this.colors.launcher.shadow) titleWidth += this.colors.launcher.margin ? this.colors.launcher.margin : 0

      return titleWidth + 20 // to simulate padding-right 20px
    }
  },
  computed: {
    isWideLauncher () {
      return this.isWide && !this.isPopUpOpen
    },
    launcherStyle () {
      const style = {
        background: this.colors.launcher.bg,
        fontFamily: this.colors.font,
        '--closed-width': `${this.buttonDimensions.buttonTitleWidth + this.buttonDimensions.buttonIconWidth}px`,
        '--icon-padding': this.colors.launcher.iconPadding || '16px',
        '--launcher-width': this.colors.launcher.width || '60px',
        '--launcher-height': this.colors.launcher.height || '60px',
        '--launcher-radius': this.colors.launcher.radius || '30px',
        '--new-messages-inset': this.colors.launcher.newMessagesInset || '0px auto auto 0px',
        '--new-messages-width': `${parseFloat(this.colors.launcher.width || 60) / 3}px`,
        '--new-messages-height': `${parseFloat(this.colors.launcher.height || 60) / 3}px`,
        '--launcher-bg-hover': this.colors.launcher.bgHover || this.colors.launcher.bg,
        '--new-messages-font-size': this.colors.launcher.newMessagesFontSize || '12px'
      }
      if (this.colors.launcher.shadow) {
        style.boxShadow = this.colors.launcher.shadow
        const adjustedMargin = this.colors.launcher.margin ? this.colors.launcher.margin / 2 : 0
        style['--shadow-margin'] = `${adjustedMargin}px`
      }
      if (this.isWide && this.isPopUpOpen) {
        style.transition = '0.3s'
      }
      if (this.colors.launcher.border) {
        style.border = this.colors.launcher.border
      }
      return style
    },
    hideLauncherOnChatOpen () {
      return this.hideLauncher === 'chatOpen'
    }
  }
}
</script>

<style scoped>

.sc-launcher {
  width: var(--launcher-width);
  height: var(--launcher-height);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: var(--launcher-radius);
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
  margin-right: var(--shadow-margin);
  margin-bottom: var(--shadow-margin);
}

.sc-launcher:hover {
  background: var(--launcher-bg-hover) !important;
}

.sc-launcher.wide.closed {
  transition: 0.3s;
  width: var(--closed-width);
}

.sc-launcher.open {
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-launcher .sc-open-icon {
  width: var(--launcher-width);
  height: var(--launcher-height);
  padding: var(--icon-padding);
  box-sizing: border-box;
}

.sc-launcher .sc-closed-icon {
  width: 30%;
  height: 30%;
}

.sc-button-text {
  color: white;
  text-align: center;
  display: flex;
  justify-items: center;
  align-items: center;
}

.sc-launcher.open .sc-button-text {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.sc-launcher.closed .sc-button-text {
  transition-property: opacity, width, height;
  transition-duration: 100ms, 1ms, 1ms;
  transition-delay: 0.3s;
  opacity: 1;
  overflow: visible;
}

.sc-icon-wrapper {
  overflow: hidden;
  display: block;
}

.sc-icon-wrapper.wide {
  display: inline-flex;
}

.sc-new-messsages-count {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  width: var(--new-messages-width);
  height: var(--new-messages-height);
  color: white;
  text-align: center;
  margin: auto;
  font-size: var(--new-messages-font-size);
  font-weight: 500;
  inset: var(--new-messages-inset);
}

@media (max-width: 150px) {
  .sc-launcher.wide.closed {
    transition: none;
    width: 60px;
  }

  .sc-icon-wrapper.wide {
    display: block;
  }

  .sc-button-text {
    display: none;
  }
}

@media (max-width: 420px) {
  .sc-launcher.open {
    display: none;
  }
}

</style>
