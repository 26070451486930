<template>
  <v-select :options="countries"
            :clearable="false"
            :searchable="true"
            label="dialCode"
            class="dial-code-select"
            :calculate-position="withPopper"
            append-to-body
            :filter="filterBy"
            v-model="selectedOption"
            @open="onOpen"
            @close="onClose"
  >
    <template #option="{ code, dialCode }">
      <div class="dial-code-select__option">
        <country-flag :country="code" size='small' class="dial-code-select__flag"/>
        <span class="dial-code-select__value"
              :style="`font-family: ${colors.font}`"
        >
          {{ `${code} (${dialCode})` }}
        </span>
      </div>
    </template>

    <template #selected-option="{ dialCode, code }">
      <div class="dial-code-select__selected-option">
        <country-flag :country="code" size='small' class="dial-code-select__flag"/>
        <span class="dial-code-select__value"
              :style="`font-family: ${colors.font}`"
        >
          {{ dialCode }}
        </span>
      </div>
    </template>

    <template #open-indicator>
      <img :src="caretIcon"
           alt="country flag"
           height="5"
           :style="`transform: rotate(${isOpen ? 180 : 0}deg); transition: .3s ease-in-out;`"
      />
    </template>

    <template #no-options="{ search }">
      <div class="dial-code-select__no-options" :style="`font-family: ${colors.font}`">{{ $t('error.dialCodeNoOptions', {term: search}) }}</div>
    </template>
  </v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
import countryData from '@/components/chat/countryData'
import caretIcon from '@/components/chat/assets/caret-icon.svg'
import { createPopper } from '@popperjs/core'
import sanitizeString from 'replace-special-characters'
import CountryFlag from 'vue-country-flag'

export default {
  name: 'DialCodeSelect',

  components: {
    vSelect,
    CountryFlag
  },

  props: {
    colors: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      caretIcon,
      isOpen: false,
      dropdown: null,
      dropdownWidths: {
        noOptions: '180px',
        options: '130px'
      }
    }
  },

  computed: {
    selectedOption: {
      get () {
        return this.countries.find(({ code }) => code === this.value)
      },
      set (country) {
        this.$emit('input', country.code)
      }
    },

    countries () {
      return countryData()
    }
  },

  methods: {
    withPopper (dropdownList, component) {
      dropdownList.style.width = this.dropdownWidths.options
      dropdownList.id = 'dial-code-select-dropdown'
      this.dropdown = dropdownList
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })

      return () => popper.destroy()
    },

    filterBy (options, searchQuery) {
      if (!searchQuery) return options

      const term = sanitizeString(searchQuery.toLowerCase())
      const filtered = options.filter(item => {
        const name = sanitizeString(item.name.toLowerCase())
        return name.includes(term) || item.dialCode.includes(term) || item.code.toLowerCase().includes(term)
      })
      this.setDropdownWidth(filtered.length)

      return filtered
    },

    setDropdownWidth (optionsCount) {
      if (!optionsCount) {
        this.dropdown.style.width = this.dropdownWidths.noOptions
      }

      if (!!optionsCount && this.dropdown.style.width === this.dropdownWidths.noOptions) {
        this.dropdown.style.width = this.dropdownWidths.options
      }
    },

    onOpen () {
      this.$emit('open')
      this.isOpen = true
    },

    onClose () {
      this.$emit('close')
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss">
$border-radius: 6px;

.dial-code-select {
  height: 100%;
  border-radius: $border-radius;

  &__value {
    margin-left: 10px;
    font-size: 14px;
    margin-bottom: 2px;
  }

  &__selected-option {
    display: flex;
    align-items: center;
  }

  &__no-options {
    padding: 5px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  .vs__dropdown-toggle {
    border-radius: $border-radius 0 0 $border-radius;
    border-bottom: none;
    border-left: none;
    border-top: none;
    width: 100px;
    padding: 0;
    height: 100%;
    cursor: pointer;
  }

  .vs__search {
    height: 0;
    margin: 0;
  }

  .vs__selected-options {
    padding: 0;
  }

  .vs__selected {
    padding: 0 0 0 15px;
    margin: 0;
    height: 100%;
    align-items: center;
  }

  .vs__actions {
    padding: 0 12px 0 0;
  }

  &__value {
    white-space: nowrap;
    overflow: hidden;
    margin-top: 1.5px;
  }
}

#dial-code-select-dropdown.vs__dropdown-menu {
  min-width: unset;
  border: 1px solid #E4E9F5;

  .vs__dropdown-option--highlight {
    background-color: #999999;
  }

  .vs__dropdown-option {
    padding: 4px 0 4px 13px;

    .dial-code-select__option {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
}

.small-flag {
  transform: scale(0.35) !important;
}
</style>
