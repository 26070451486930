<template>
  <div class="validated-input">
    <div class="form"
         :class="[{ 'validated-input__error': !isInputValid }, animationClass]"
         :style="`boxShadow: ${colorShadow} 0 ${isFocused ? 0 : 2}px ${isFocused ? 6 : 16}px 0; backgroundColor:white;`"
    >
      <dial-code-select v-if="isPhone"
                      :colors="colors"
                      v-model="countryCode"
                      @close="isFocused = false"
                      @open="isFocused = true"
      />

      <div class="form__input">
        <input class="form__email-input"
               ref="input"
               :placeholder="validationParams.label"
               :style="`font-family: ${colors.font}`"
               v-model.trim="inputValue"
               data-1p-ignore
               @keyup.enter="submitMessage"
               @blur="onInputBlur"
               @focus="isFocused = true"
        />
        <button class="form__button"
                :class="{'form__button__disabled': !isInputValid}"
                :style="buttonStyle"
                @click="submitMessage"
        >
          <icon-base v-if="isInputValid" :icon-name="isInputValid ? 'send' : ''">
            <icon-send/>
          </icon-base>
          <span v-else class="exclamation-mark">!</span>
        </button>
      </div>
    </div>

    <span v-if="!isInputValid" class="validated-input__error-message fade-in">
      {{ validationParams.errorMessage }}
    </span>
  </div>
</template>

<script>
import IconSend from '@/components/chat/icons/icons/IconSend'
import IconBase from '@/components/chat/icons/IconBase'
import DialCodeSelect from '@/components/chat/DialCodeSelect.vue'
import { Event, EventBus } from '@/util/event-bus'
import countryData from '@/components/chat/countryData'

export default {
  name: 'ValidatedInput',

  components: {
    IconSend,
    IconBase,
    DialCodeSelect
  },

  props: {
    colors: {
      type: Object,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    validationParams: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isFocused: false,
      inputValue: '',
      countryCode: '',
      isInputValid: true,
      validationRegex: '',
      animationClass: ''
    }
  },

  computed: {
    buttonStyle () {
      return {
        background: this.isInputValid ? this.colors.sentMessage.bg : '#DD3535',
        color: this.colors.sentMessage.text
      }
    },

    isPhone () {
      return this.validationParams.pattern === 'phone'
    },

    colorShadow () {
      if (this.isFocused) return this.isInputValid ? this.colors.sentMessage.bg : '#DD3535'

      return '#30323514'
    },

    dialCodeAndPhoneNumber () {
      const dialCode = this.convertCountryCodeToDialCode(this.countryCode)
      const phoneNumber = this.inputValue.replace(/\s/g, '')
      return `${dialCode}${phoneNumber}`
    },

    defaultCountryCode () {
      return countryData().find(({ lang }) => lang === this.$i18n.locale).code
    }
  },

  methods: {
    async resolveAnimationClass () {
      this.animationClass = this.isInputValid ? '' : 'shake-horizontal'
    },

    validateInput () {
      this.isInputValid = this.validationRegex.test(this.inputValue)
    },

    submitMessage () {
      this.validateInput()
      if (!this.inputValue) this.isInputValid = false
      this.resolveAnimationClass()
      if (!this.isInputValid) return

      const text = this.isPhone ? this.dialCodeAndPhoneNumber : this.inputValue
      this.onSubmit(this.createClientMessage('text', { text }))
      EventBus.$emit(Event.INPUT_HIDE_VALIDATION)
    },

    createClientMessage (type, data) {
      return {
        author: 'client',
        direction: 'sent',
        type,
        data,
        error: null,
        metadata: {
          bubble: {
            type: 'validation',
            field: this.validationParams.label
          }
        }
      }
    },

    onInputBlur () {
      this.isFocused = false
      if (this.inputValue) this.validateInput()
      if (!this.isInputValid && this.inputValue) this.resolveAnimationClass()
    },

    convertCountryCodeToDialCode (countryCode) {
      const country = countryData().find(country => country.code === countryCode)
      return countryCode ? country.dialCode : this.convertCountryCodeToDialCode(this.defaultCountryCode)
    },

    convertDialCodeToCountryCode (dialCode) {
      const country = countryData().find(country => {
        if (country.code === 'CA') return country.code === 'US'

        return country.dialCode === dialCode
      })

      return country ? country.code : this.defaultCountryCode
    }
  },

  watch: {
    inputValue () {
      if (this.isInputValid) return

      this.validateInput()
      this.resolveAnimationClass()
    },

    async countryCode () {
      await this.$nextTick()
      this.$refs.input.focus()
    }
  },

  mounted () {
    if (this.isPhone) {
      this.countryCode = this.convertDialCodeToCountryCode(this.validationParams.defaultPrefix)
    }

    this.validationRegex = new RegExp(this.validationParams.regex)
    this.$refs.input.focus()
  }
}
</script>

<style scoped lang="scss">
.validated-input {
  display: flex;
  flex-direction: column;

  &__error {
    border-color: #DD3535 !important;
  }

  &__error-message {
    color: #DD3535;
    font-size: 12px;
    margin-left: 2px;
    margin-top: 2px;
  }

  .form {
    border-radius: 0 6px 6px 6px;
    border: 1px solid #E4E9F5;
    align-items: center;
    display: flex;

    &__email-input {
      color: #303235;
      padding: 0;
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
    }

    &__button {
      border: none;
      border-radius: 4px;
      min-width: 31px;
      height: 32px;
      cursor: pointer;

      &__disabled {
        opacity: 0.5;
        cursor: not-allowed;

        .exclamation-mark {
          color: #fff;
          font-size: 22px;
          font-weight: bold;
        }
      }
    }

    &__input {
      padding: 3px 3px 3px 20px;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
