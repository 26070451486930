<template>
  <div :style="{backgroundColor: statusBarType.backgroundColor}" class="sc-status-bar" @click="$emit('click')">
    <p :style="{color: statusBarType.textColor}" class="sc-status-text">{{statusBarType.text}}
      <v-icon v-if="statusBarType.icon" :name="statusBarType.icon" scale="0.8" class="sc-status-icon"/></p>

  </div>
</template>

<script>
import 'vue-awesome/icons/chevron-down'
import Type from '../../enum/status-bar-type.enum'

export default {
  data () {
    return {
      types: {
        [Type.UNREAD_MESSAGES]: {
          text: this.$t('interface.newMessages'),
          textColor: this.colors.statusInfo.text,
          backgroundColor: this.colors.statusInfo.bg,
          icon: 'chevron-down'
        },
        [Type.OFFLINE]: {
          text: this.$t('interface.chatOffline'),
          textColor: 'white',
          backgroundColor: '#DD3535'
        }
      }
    }
  },
  props: {
    type: {
      required: true
    },
    colors: {
      required: true
    }
  },
  methods: {

  },
  computed: {
    statusBarType () {
      return this.types[this.type]
    }
  }
}
</script>

<style lang="scss" scoped>

.sc-status-bar {
  display: block;
  text-align: center;
}

.sc-status-text {
  margin: 5px;
  line-height: 1.4em;
  font-size: 80%;
}

.sc-status-icon {
  padding-left: 8px;
  vertical-align:middle;
}

</style>
