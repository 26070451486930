var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"dial-code-select",attrs:{"options":_vm.countries,"clearable":false,"searchable":true,"label":"dialCode","calculate-position":_vm.withPopper,"append-to-body":"","filter":_vm.filterBy},on:{"open":_vm.onOpen,"close":_vm.onClose},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var code = ref.code;
var dialCode = ref.dialCode;
return [_c('div',{staticClass:"dial-code-select__option"},[_c('country-flag',{staticClass:"dial-code-select__flag",attrs:{"country":code,"size":"small"}}),_c('span',{staticClass:"dial-code-select__value",style:(("font-family: " + (_vm.colors.font)))},[_vm._v(" "+_vm._s((code + " (" + dialCode + ")"))+" ")])],1)]}},{key:"selected-option",fn:function(ref){
var dialCode = ref.dialCode;
var code = ref.code;
return [_c('div',{staticClass:"dial-code-select__selected-option"},[_c('country-flag',{staticClass:"dial-code-select__flag",attrs:{"country":code,"size":"small"}}),_c('span',{staticClass:"dial-code-select__value",style:(("font-family: " + (_vm.colors.font)))},[_vm._v(" "+_vm._s(dialCode)+" ")])],1)]}},{key:"open-indicator",fn:function(){return [_c('img',{style:(("transform: rotate(" + (_vm.isOpen ? 180 : 0) + "deg); transition: .3s ease-in-out;")),attrs:{"src":_vm.caretIcon,"alt":"country flag","height":"5"}})]},proxy:true},{key:"no-options",fn:function(ref){
var search = ref.search;
return [_c('div',{staticClass:"dial-code-select__no-options",style:(("font-family: " + (_vm.colors.font)))},[_vm._v(_vm._s(_vm.$t('error.dialCodeNoOptions', {term: search})))])]}}]),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})}
var staticRenderFns = []

export { render, staticRenderFns }