<template>
  <icon-base offset-correction="-3px" :icon-name="$t('interface.attachFile') ">
    <title v-if="title">{{title}}</title>
    <path :style="{fill: color}"
          d="M5.28 10.609L10.584 5.30503C10.8394 5.04968 11.1426 4.84713 11.4763 4.70896C11.81 4.57079 12.1677 4.4997 12.5289 4.49974C12.89 4.49979 13.2477 4.57097 13.5813 4.70923C13.915 4.84749 14.2181 5.05011 14.4735 5.30553C14.7289 5.56096 14.9314 5.86417 15.0696 6.19787C15.2077 6.53157 15.2788 6.88921 15.2788 7.25039C15.2787 7.61156 15.2076 7.96919 15.0693 8.30285C14.931 8.63651 14.7284 8.93968 14.473 9.19503L8.109 15.559C7.87442 15.7936 7.55625 15.9254 7.2245 15.9254C6.89275 15.9254 6.57458 15.7936 6.34 15.559C6.10542 15.3245 5.97363 15.0063 5.97363 14.6745C5.97363 14.3428 6.10542 14.0246 6.34 13.79L11.997 8.13303C12.1295 7.99086 12.2016 7.80281 12.1982 7.60851C12.1947 7.41421 12.116 7.22883 11.9786 7.09141C11.8412 6.954 11.6558 6.87529 11.4615 6.87186C11.2672 6.86843 11.0792 6.94055 10.937 7.07303L5.28 12.73C4.76415 13.2459 4.47435 13.9455 4.47435 14.675C4.47435 15.4046 4.76415 16.1042 5.28 16.62C5.79585 17.1359 6.49548 17.4257 7.225 17.4257C7.95452 17.4257 8.65415 17.1359 9.17 16.62L15.533 10.255C16.3147 9.45491 16.7494 8.37886 16.7429 7.26029C16.7364 6.14171 16.2892 5.0708 15.4982 4.27983C14.7072 3.48886 13.6363 3.04162 12.5177 3.03511C11.3992 3.02859 10.3231 3.46333 9.523 4.24503L4.22 9.54803C4.08752 9.69021 4.0154 9.87826 4.01882 10.0726C4.02225 10.2669 4.10096 10.4522 4.23838 10.5897C4.37579 10.7271 4.56118 10.8058 4.75548 10.8092C4.94978 10.8126 5.13782 10.7405 5.28 10.608V10.609Z"
          fill="#626262"
          fill-opacity="0.7"
    />
  </icon-base>
</template>

<script>
import IconBase from '@/components/chat/icons/IconBase'

export default {
  components: {
    IconBase
  },

  props: {
    color: {
      type: String,
      required: true
    },
    title: {
      type: String
    }
  }
}
</script>
