<template>
  <div class="sc-message--text" :style="messageColors">
    <!-- template>
      <div class="sc-message--toolbox" :style="{background: messageColors.backgroundColor}">
        <button v-if="me && message.id != null && message.id != undefined" @click="edit" :disabled="isEditing">
          <icon-base :color="isEditing? 'black': messageColors.color" width="10" icon-name="edit">
            <icon-edit />
          </icon-base>
        </button>
        <button v-if="me && message.id != null && message.id != undefined" @click="$emit('remove')">
          <icon-base :color="messageColors.color" width="10" icon-name="remove">
            <icon-cross />
          </icon-base>
        </button>
        <slot name="text-message-toolbox" :message="message" :me="me">
        </slot>
      </div>
    </!-->
    <slot name="label"/>
    <slot :message="message" :messageText="messageText" :messageColors="messageColors" :me="me">
      <p class="sc-message--text-content" v-html="messageText"></p>
      <p v-if="message.data.meta" class='sc-message--meta' :style="{color: messageColors.color}">{{message.data.meta}}</p>
      <p v-if="message.isEdited" class='sc-message--edited'>
        <icon-base width="10" icon-name="edited">
          <icon-edit />
        </icon-base> edited
      </p>
    </slot>
  </div>
</template>

<script>
import IconBase from '../icons/IconBase.vue'
import IconEdit from '../icons/icons/IconEdit.vue'
import escapeGoat from 'escape-goat'
import Autolinker from 'autolinker'
import DOMPurify from 'dompurify'
import marked from 'marked'
const fmt = require('msgdown')

export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    messageColors: {
      type: Object,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    messageText () {
      const escaped = escapeGoat.escape(this.message.data.text)
      const markdownLinkRegex = /\[(.*?)\]\((.*?)(?:\)|…)/g
      const markdownLinksToAnchor = escaped.replace(markdownLinkRegex, (match, text, url) => `<a href="${url}" target="_blank" rel="noopener noreferrer nofollow" class="chat-link">${text}</a>`)
      const urlStringsToAnchor = Autolinker.link(this.messageStyling ? fmt(markdownLinksToAnchor) : markdownLinksToAnchor, {
        className: 'chat-link',
        truncate: { length: 50, location: 'smart' }
      }).replace(/\n/g, ' <br />\n')
      const htmlString = marked.parse(urlStringsToAnchor)
      return DOMPurify.sanitize(htmlString, { ADD_ATTR: ['target'] })
    },
    me () {
      return this.message.author === 'client'
    }
  },
  components: {
    IconBase,
    IconEdit
  }
}
</script>

<style>
.chat-link {
  color: inherit !important;
}
</style>
