import { countries } from 'countries-list'

const countryData = () => {
  const countryData = Object.entries(countries).map(([code, country]) => {
    return {
      name: country.name,
      dialCode: `+${country.phone}`.split(',')[0],
      lang: country.languages[0],
      code
    }
  })

  return countryData.sort((a, b) => a.code.localeCompare(b.code))
}

export default countryData
