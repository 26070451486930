<template>
  <div class="sc-header"
       :class="{'rounded-top': !forceFullScreen}"
       :style="{background: colors.header.bg, color: colors.header.text, fontFamily: colors.font}"
  >
    <slot v-if="colors.header.logoOnly">
      <img class="sc-header--img" :src="imageUrl" alt="" height="100%"/>
    </slot>
    <slot v-else>
      <img class="sc-header--img border-radius-50" :src="imageUrl" alt="" v-if="imageUrl" height="36px" width="36px"/>
      <div v-if="!disableUserListToggle" class="sc-header--title enabled" @click="toggleUserList"> {{title}} </div>
      <div v-else class="sc-header--title" :style="{fontSize: colors.header.fontSize, padding: colors.header.padding}" v-html="$t('welcome')"></div>
    </slot>

    <div v-if="showCloseButton" class="sc-header--close-button" @click="onClose">
      <icon-cross class="display-none display-mobile-block" :fill="colors.header.text"/>
      <icon-arrow-down class="display-block display-mobile-none" :fill="colors.header.text"/>
    </div>
  </div>
</template>
<script>

import IconCross from './icons/icons/IconCross'
import IconArrowDown from './icons/icons/IconArrowDown'

export default {
  components: { IconCross, IconArrowDown },
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    onClose: {
      type: Function,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    disableUserListToggle: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    forceFullScreen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleUserList () {
      this.inUserList = !this.inUserList
      this.$emit('userList', this.inUserList)
    }
  },
  data () {
    return {
      inUserList: false
    }
  }
}
</script>
<style>
.font-light {
  font-weight: 300;
}

.sc-header {
  height: 60px;
  min-height: 60px;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.sc-header--img {
  align-self: center;
  padding: 10px;
}

.border-radius-50 {
  border-radius: 50%;
}

.sc-header--title {
  align-self: center;
  flex: 1;
  font-size: 20px;
}

.sc-header--title.enabled {
  cursor: pointer;
  border-radius: 5px;
}

.sc-header--title.enabled:hover {
  box-shadow: 0px 2px 5px rgba(0.2, 0.2, 0.5, .1);
}

.sc-header--close-button {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-left: auto;
  align-self: center;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.sc-header--close-button svg {
  width: 100%;
  height: 100%;
  padding: 13px;
  box-sizing: border-box;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.rounded-top {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

@media (max-width: 420px) {
  .sc-header {
    border-radius: 0;
  }

  .display-mobile-none {
    display: none !important;
  }

  .display-mobile-block {
    display: block !important;
  }
}
</style>
