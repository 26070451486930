<template>
  <div class="sc-chat-window"
       :class="windowClasses"
       :style="{fontFamily: colors.font}"
  >
    <Header
      v-if="showHeader"
      :showCloseButton="showCloseButton"
      :title="title"
      :imageUrl="titleImageUrl"
      :onClose="handleClose"
      :colors="colors"
      :disableUserListToggle="disableUserListToggle"
      :forceFullScreen="forceFullScreen"
      @userList="handleUserListToggle"
    >
      <template>
        <slot name="header">
        </slot>
      </template>
    </Header>
    <UserList
      v-if="showUserList"
      :participants="participants"
    />

    <status-bar v-if="statusBarVisible" :type="statusBarType" :colors="colors" class="sc-status-bar"
                @click="onStatusBarClick"/>

    <MessageList
      :style="statusBarVisible ? 'margin-top: -27px;' : ''"
      v-if="!showUserList"
      :messages="messages"
      :participants="participants"
      :showTypingIndicator="showTypingIndicator"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :messageStyling="messageStyling"
      :onButtonPressed="onButtonPressed"
      :onSubmit="onUserInputSubmit"
      @remove="$emit('remove', $event)"
    >
      <template v-slot:user-avatar="scopedProps">
        <slot name="user-avatar" :user="scopedProps.user" :message="scopedProps.message">
        </slot>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <slot name="text-message-body" :message="scopedProps.message" :messageText="scopedProps.messageText"
              :messageColors="scopedProps.messageColors" :me="scopedProps.me">
        </slot>
      </template>
      <template v-slot:system-message-body="scopedProps">
        <slot name="system-message-body" :message="scopedProps.message">
        </slot>
      </template>
      <template v-slot:text-message-toolbox="scopedProps">
        <slot name="text-message-toolbox" :message="scopedProps.message" :me="scopedProps.me">
        </slot>
      </template>
    </MessageList>

    <UserInput
      v-show="!showUserList && displayUserInput"
      :showEmoji="showEmoji"
      :onSubmit="onUserInputSubmit"
      :onButtonPressed="onButtonPressed"
      :showFile="showFile"
      :showVoice="showVoice"
      :placeholder="$t('interface.writeMessage')"
      @onType="$emit('onType')"
      :colors="colors"
      :powered-by-amio-config="poweredByAmioConfig"
      :class="userInputAnimationClass"
    />
    <powered-by-amio v-if="poweredByAmioConfig.enabled && !colors.launcher.bg.includes('gradient')" :amio-color="colors.launcher.bg" :utm-source="poweredByAmioConfig.utmSource"/>
    <powered-by-amio v-else-if="poweredByAmioConfig.enabled && colors.launcher.bg.includes('gradient')" :amio-color="colors.sentMessage.bg" :utm-source="poweredByAmioConfig.utmSource"/>
  </div>
</template>

<script>
import Header from './Header.vue'
import MessageList from './MessageList.vue'
import UserInput from './UserInput.vue'
import UserList from './UserList.vue'
import StatusBar from './StatusBar'
import { EventBus, Event } from '../../util/event-bus'
import USER_EVENT from '../../util/user-event.constants'
import StatusBarType from '../../enum/status-bar-type.enum'
import { ANIMATION_TIME } from '@/util/constants'
import { sleep } from '@/util/mixins'
import PoweredByAmio from '@/components/chat/PoweredByAmio'

export default {
  components: {
    Header,
    MessageList,
    UserInput,
    UserList,
    StatusBar,
    PoweredByAmio
  },
  props: {
    showEmoji: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showFile: {
      type: Boolean,
      default: false
    },
    showVoice: {
      type: Boolean,
      default: false
    },
    participants: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    titleImageUrl: {
      type: String,
      default: ''
    },
    onUserInputSubmit: {
      type: Function,
      required: true
    },
    onButtonPressed: {
      type: Function,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    messageList: {
      type: Array,
      default: () => []
    },
    isOpen: {
      type: Boolean,
      default: () => false
    },
    showTypingIndicator: {
      type: Boolean,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    alwaysScrollToBottom: {
      type: Boolean,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    },
    disableUserListToggle: {
      type: Boolean,
      default: false
    },
    forceFullScreen: {
      type: Boolean,
      default: false
    },
    hideLauncher: {
      type: String,
      default: ''
    },
    poweredByAmioConfig: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showUserList: false,
      statusBarType: null,
      imageExpanded: false,
      animationClass: 'slide-in-bottom',
      displayUserInput: true,
      userInputAnimationClass: 'slide-in-bottom-500'
    }
  },
  computed: {
    messages () {
      const messages = this.messageList

      return messages
    },
    statusBarVisible () {
      return this.statusBarType !== null
    },
    hideLauncherOnChatOpen () {
      return this.hideLauncher === 'chatOpen'
    },
    windowClasses () {
      return [
        this.hideLauncherOnChatOpen ? `${this.animationClass}` : 'fade-in',
        {
          'image-expanded': this.imageExpanded,
          'forced-full-screen': this.forceFullScreen,
          closed: !this.isOpen
        }
      ]
    }
  },
  methods: {
    handleUserListToggle (showUserList) {
      this.showUserList = showUserList
    },
    onStatusBarClick () {
      EventBus.$emit(Event.STATUS_BAR_CLICK, this.statusBarType)
    },
    showStatusBar (type) {
      this.statusBarType = type
    },
    hideStatusBar (type) {
      if (this.statusBarType === type) {
        this.statusBarType = null
      }
    },
    setImageExpanded (status) {
      this.imageExpanded = status
    },
    async handleClose () {
      if (this.hideLauncherOnChatOpen) await this.resolveAnimation()

      this.onClose(USER_EVENT.HEADER_CLICK_CLOSE)
    },
    async resolveAnimation () {
      this.animationClass = 'slide-out-bottom'
      await sleep(ANIMATION_TIME)
      this.animationClass = 'slide-in-bottom'
    },

    async hideUserInput () {
      this.userInputAnimationClass = 'slide-out-bottom-500'
      await sleep(ANIMATION_TIME)
      this.displayUserInput = false
    },

    async showUserInput () {
      this.userInputAnimationClass = 'slide-in-bottom-500'
      await sleep(ANIMATION_TIME)
      this.displayUserInput = true
    }
  },
  mounted () {
    EventBus.$on(Event.CHAT_UNREAD_MESSAGES, () => this.showStatusBar(StatusBarType.UNREAD_MESSAGES))
    EventBus.$on(Event.CHAT_SCROLL_BOTTOM, () => this.hideStatusBar(StatusBarType.UNREAD_MESSAGES))
    EventBus.$on(Event.CHAT_ONLINE, () => this.hideStatusBar(StatusBarType.OFFLINE))
    EventBus.$on(Event.CHAT_OFFLINE, () => this.showStatusBar(StatusBarType.OFFLINE))
    EventBus.$on(Event.IMAGE_EXPANDED, this.setImageExpanded)
    EventBus.$on(Event.INPUT_SHOW_VALIDATION, this.hideUserInput)
    EventBus.$on(Event.INPUT_HIDE_VALIDATION, this.showUserInput)
  }
}
</script>

<style scoped>

.sc-status-bar {
  width: 100%;
  z-index: 10;
  cursor: pointer;
}

.sc-chat-window {
  width: 370px;
  min-height: 300px;
  height: 100%;
  max-height: calc(100% - 100px);
  box-sizing: border-box;
  box-shadow: 0px 7px 30px 2px rgba(148, 149, 150, 0.1);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  flex: 1;
}

.sc-chat-window.image-expanded {
  height: 800px;
  max-height: calc(100% - 200px);
}

.sc-chat-window.closed {
  opacity: 0;
  display: none;
  bottom: 90px;
}

.sc-message--me {
  text-align: right;
}

.sc-message--them {
  text-align: left;
}

.forced-full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0;
  margin-right: 0 !important;
  z-index: 1;
}

@media (max-width: 420px) {
  .sc-chat-window {
    position: fixed;
    width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0px;
    margin-right: 0 !important;
  }

  .sc-chat-window.closed {
    bottom: 0px;
  }

  .sc-chat-window.image-expanded {
    height: 100%;
    max-height: 100%;
  }
}

</style>
