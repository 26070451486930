<template>
  <path
    d="M8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0ZM5.81 5.114C5.71369 5.04745 5.59708 5.0168 5.48049 5.0274C5.3639 5.038 5.25473 5.08917 5.172 5.172L5.114 5.241C5.04745 5.33731 5.0168 5.45392 5.0274 5.57051C5.038 5.6871 5.08917 5.79627 5.172 5.879L7.292 8L5.172 10.121L5.114 10.191C5.04771 10.2872 5.01722 10.4036 5.02782 10.52C5.03841 10.6364 5.08943 10.7453 5.172 10.828L5.241 10.886C5.33731 10.9526 5.45392 10.9832 5.57051 10.9726C5.6871 10.962 5.79627 10.9108 5.879 10.828L8 8.708L10.121 10.828L10.191 10.886C10.2872 10.9523 10.4036 10.9828 10.52 10.9722C10.6364 10.9616 10.7453 10.9106 10.828 10.828L10.886 10.759C10.9526 10.6627 10.9832 10.5461 10.9726 10.4295C10.962 10.3129 10.9108 10.2037 10.828 10.121L8.708 8L10.828 5.879L10.886 5.809C10.9523 5.71278 10.9828 5.59638 10.9722 5.48001C10.9616 5.36365 10.9106 5.25467 10.828 5.172L10.759 5.114C10.6627 5.04745 10.5461 5.0168 10.4295 5.0274C10.3129 5.038 10.2037 5.08917 10.121 5.172L8 7.292L5.879 5.172L5.809 5.114H5.81Z"
    fill="#626262"
    fill-opacity="0.7"/>
</template>

<script>
export default {
  name: 'IconClear'
}
</script>
