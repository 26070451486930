
class Adapter {
  convertMessage (message) {
    const converted = {
      author: message.direction === 'received' ? 'bot' : 'client',
      direction: message.direction,
      type: message.content.type,
      id: message.id,
      isEdited: false,
      data: this._convertPayload(message.content)
    }
    if (message.content.quick_replies) {
      converted.suggestions = message.content.quick_replies
    }

    if (message.metadata) converted.metadata = message.metadata

    return converted
  }

  _convertPayload (content) {
    if (content.type === 'text') return { text: content.payload }
    if (content.type === 'structure') return content.payload
    if (content.type === 'file') return { url: content.payload.url }
    return content.payload
  }
}

export default new Adapter()
