<template>
  <div class="sc-suggestions-row" :style="containerStyle">
    <button class="sc-suggestions-element" v-for="(suggestion, idx) in filteredSuggestions" v-on:click="$emit('sendSuggestion', suggestion)"
    :style="buttonStyle" :key="idx">{{suggestion.title}}</button>
  </div>
</template>

<script>
export default {
  props: {
    suggestions: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Object,
      required: true
    },
    justifyContent: {
      type: String,
      default: 'start'
    }
  },
  computed: {
    containerStyle () {
      return {
        background: this.justifyContent === 'end' ? 'transparent' : this.colors.sentMessage.wrapperBg,
        justifyContent: this.justifyContent
      }
    },
    buttonStyle () {
      return {
        '--color': this.colors.sentMessage.qr.text,
        '--border-color': this.colors.sentMessage.qr.borderColor,
        '--background-color-hover': this.colors.sentMessage.qr.bgHover,
        '--border-color-hover': this.colors.sentMessage.qr.borderColorHover,
        '--color-hover': this.colors.sentMessage.qr.textHover,
        'font-family': this.colors.font
      }
    },
    filteredSuggestions () {
      return this.suggestions.filter(e => e.type === 'text')
    }
  }
}
</script>

<style>
.sc-suggestions-row {
  margin-top: 15px;
  text-align: center;
  background: inherit;
  display: flex;
  flex-wrap: wrap;
}

.sc-suggestions-element {
  background: white;
  margin: 3px;
  padding: 5px 10px 5px 10px;
  border: 1px solid;
  border-radius: 15px;
  font-size: 14px;
  cursor: pointer;
  color: var(--color);
  border-color: var(--border-color);
}

.sc-suggestions-element:hover {
  color: var(--color-hover);
  background-color: var(--background-color-hover);
  border-color: var(--border-color-hover);
}
</style>
