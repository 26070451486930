<template>
<svg width="100%" height="100%" viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
  <path
    d="M8,1.143L5.143,4L8,6.857L6.857,8L4,5.143L1.143,8L0.001,6.857L2.857,4L0.001,1.143L1.142,0L4,2.857L6.857,0L8,1.143Z"
    style="fill:white;fill-rule:nonzero;"/>
</svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String
    }
  }
}
</script>
