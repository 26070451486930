<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/"
       class="wave" viewBox="0 0 30 35" version="1.1" xml:space="preserve"
  >
    <g class="line-1">
        <path
          d="M1,15L0.78,15C0.317,15.104 -0.012,15.525 0,16L0,22C0,22.549 0.451,23 1,23C1.549,23 2,22.549 2,22C2,21.451 2,22 2,22L2,16C2,15.451 1.549,15 1,15Z"
        />
    </g>
    <g class="line-2">
        <path
          d="M7,9L6.78,9C6.317,9.104 5.988,9.525 6,10L6,28C6,28.549 6.451,29 7,29C7.549,29 8,28.549 8,28C8,27.451 8,28 8,28L8,10C8,9.451 7.549,9 7,9Z"
        />
    </g>
    <g class="line-3">
        <path
          d="M13,0L12.78,0C12.317,0.104 11.988,0.525 12,1L12,37C12,37.549 12.451,38 13,38C13.549,38 14,37.549 14,37C14,36.451 14,37 14,37L14,1C14,0.451 13.549,0 13,0Z"
        />
    </g>
    <g class="line-4">
        <path
          d="M19,10L18.79,10C18.323,10.1 17.989,10.522 18,11L18,27C18,27.549 18.451,28 19,28C19.549,28 20,27.549 20,27C20,26.451 20,27 20,27L20,11C20,10.451 19.549,10 19,10Z"
        />
    </g>
    <g class="line-5">
        <path
          d="M25,15L24.79,15C24.323,15.1 23.989,15.522 24,16L24,22C24,22.549 24.451,23 25,23C25.549,23 26,22.549 26,22C26,21.451 26,22 26,22L26,16C26,15.451 25.549,15 25,15Z"
        />
    </g>
</svg>
</template>

<script>
export default {
  name: 'IconSoundWave'
}
</script>

<style scoped lang="scss">
.wave {
  height: 20px;
  width: 15px;
}

@for $i from 1 through 5 {
  .line-#{$i} {
    animation: pulse 1s infinite;
    animation-delay: $i * .15s;
  }
}

@keyframes pulse {
  0% {
    transform: scaleY(.9);
    transform-origin: 50% 50%;
  }

  50% {
    transform: scaleY(.4);
    transform-origin: 50% 50%;
  }

  100% {
    transform: scaleY(.9);
    transform-origin: 50% 50%;
  }
}
</style>
