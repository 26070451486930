<template>
  <button class="sc-user-input--button-icon-wrapper"
          :disabled="disabled"
          :class="disabled ? 'disabled' : ''"
          v-on="$listeners"
  >
    <icon-base :icon-color="iconColor"
               :icon-name="tooltip"
               :height="iconHeight"
               :width="iconWidth"
               :offset-correction-y="iconOffsetY"
               :offset-correction-x="iconOffsetX">
      <slot/>
    </icon-base>
  </button>
</template>

<script>
import IconBase from './icons/IconBase.vue'

export default {
  props: {
    iconColor: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconOffsetY: {
      type: String
    },
    iconOffsetX: {
      type: String
    },
    iconHeight: {
      type: [String, Number],
      default: '20'
    },
    iconWidth: {
      type: [String, Number],
      default: '20'
    },
    soundDetected: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IconBase
  }
}
</script>

<style lang="scss" scoped>
.sc-user-input--button-icon-wrapper {
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    align-self: center;
    outline: none;

    &:hover path {
      filter: contrast(15%);
    }
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
}
</style>
