<template>
  <div class="image-container">
    <img :src="data.url" class="image-content" @click="setModal(true)"/>
    <div v-if="modalOpen" class="modal" @click="setModal(false)">
      <img class="modal-content" :src="data.url">
    </div>
  </div>
</template>

<script>
import { EventBus, Event } from '../../../util/event-bus'

export default {
  props: {
    data: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      modalOpen: false
    }
  },
  methods: {
    setModal (status) {
      this.modalOpen = status
      EventBus.$emit(Event.IMAGE_EXPANDED, status)
    }
  }
}
</script>

<style scoped>
.image-container {
  overflow: hidden;
}
.image-content {
  display: block;
  width: 100%;
  height: auto;
  cursor: pointer;
}
.modal {
  position: fixed;
  z-index: 1;
  display: flex;
  justify-items: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0,0,0,0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
</style>
