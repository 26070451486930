<template>
  <div class="sc-message">
    <div class="sc-message--content" :class="{
        system: message.type === 'system',
        sent: message.direction === 'sent' && message.type !== 'system',
        received: message.direction === 'received' && message.type !== 'system'
      }"
      :style="{width: colors.messageBubble.width}"
    >
      <slot
        v-if="message.direction === 'received' && chatImageUrl"
        name="user-avatar"
        :message="message"
        :user="user">
          <div v-if="message.type !== 'system'" :title="authorName" class="sc-message--avatar" :style="{
            backgroundImage: `url(${chatImageUrl})`
          }"></div>
      </slot>

      <TextMessage
        :class="messageClass"
        v-if="message.type === 'text'"
        :message="message"
        :messageColors="determineMessageColors()"
        :messageStyling="messageStyling"
        @remove="$emit('remove')"
      >
        <template v-slot:default="scopedProps">
          <slot name="text-message-body" :message="scopedProps.message" :messageText="scopedProps.messageText"
                :messageColors="scopedProps.messageColors" :me="scopedProps.me">
          </slot>
        </template>

        <template v-slot:text-message-toolbox="scopedProps">
          <slot name="text-message-toolbox" :message="scopedProps.message" :me="scopedProps.me">
          </slot>
        </template>

        <template #label v-if="bubbleMetadata.type === 'validation'">
          <p class="bubble-label">{{ bubbleMetadata.field }}</p>
        </template>
      </TextMessage>
      <EmojiMessage v-else-if="message.type === 'emoji'" :data="message.data" />
      <FileMessage v-else-if="message.type === 'file'" :data="message.data" :messageColors="colors" :direction="message.direction" :class="messageClass" />
      <ImageMessage v-else-if="message.type === 'image'" :data="message.data" :class="messageClass" />
      <TypingMessage v-else-if="message.type === 'typing'" :indent="!!chatImageUrl" :messageColors="determineMessageColors()" :class="messageClass" />
      <SystemMessage v-else-if="message.type === 'system'" :data="message.data" :messageColors="determineMessageColors()">
          <slot name="system-message-body" :message="message.data">
          </slot>
      </SystemMessage>
      <StructureMessage v-else-if="message.type === 'structure'" :data="message.data" :messageColors="determineMessageColorsStructure()" @buttonPressed="triggerButtonPressed" :class="messageClass" />
    </div>

    <Suggestions v-if="isLastMessage && message.suggestions" :suggestions="message.suggestions" v-on:sendSuggestion="triggerQuickReplyPressed" :colors="colors" />

    <div v-if="message.direction === 'sent' && message.error" class="sc-message-error">
      {{message.error}}
    </div>
  </div>
</template>

<script>
import TextMessage from './messages/TextMessage.vue'
import FileMessage from './messages/FileMessage.vue'
import EmojiMessage from './messages/EmojiMessage.vue'
import TypingMessage from './messages/TypingMessage.vue'
import SystemMessage from './messages/SystemMessage.vue'
import StructureMessage from './messages/StructureMessage.vue'
import ImageMessage from './messages/ImageMessage.vue'
import Suggestions from './Suggestions.vue'
import { Event, EventBus } from '@/util/event-bus'

export default {
  components: {
    TextMessage,
    FileMessage,
    EmojiMessage,
    TypingMessage,
    SystemMessage,
    StructureMessage,
    ImageMessage,
    Suggestions
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    isLastMessage: {
      type: Boolean,
      default: false
    },
    onButtonPressed: {
      type: Function,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    messageLabel: {
      type: String,
      default: ''
    }
  },

  methods: {
    sentColorsStyle (isStruct = false) {
      const styles = {
        color: this.colors.sentMessage.text,
        backgroundColor: isStruct ? this.colors.sentMessage.button.bg : this.colors.sentMessage.bg,
        fontSize: this.colors.messageBubble.fontSize,
        fontWeight: this.colors.messageBubble.fontWeight
      }
      if (isStruct) {
        styles.border = this.colors.sentMessage.button.border
        styles['--button-text'] = this.colors.sentMessage.button.text
        styles['--button-text-hover'] = this.colors.sentMessage.button.textHover
        styles['--button-bg-hover'] = this.colors.sentMessage.button.bgHover
        styles['--button-radius'] = this.colors.sentMessage.button.radius || '6px'
      } else {
        styles.lineHeight = this.colors.messageBubble.lineHeight
        styles.padding = this.colors.messageBubble.paddingText
      }
      return styles
    },
    receivedColorsStyle (isStruct = false) {
      return {
        color: this.colors.receivedMessage.text,
        marginRight: this.colors.receivedMessage.marginRight,
        backgroundColor: this.colors.receivedMessage.bg,
        fontSize: this.colors.messageBubble.fontSize,
        fontWeight: this.colors.messageBubble.fontWeight,
        lineHeight: this.colors.messageBubble.lineHeight,
        padding: isStruct ? this.colors.messageBubble.paddingStruct : this.colors.messageBubble.paddingText,
        opacity: this.colors.receivedMessage.opacity || '100%',
        backdropFilter: this.colors.receivedMessage.backdropFilter || null,
        boxShadow: this.colors.receivedMessage.boxShadow || null
      }
    },
    determineMessageColors () {
      return this.message.direction === 'sent' ? this.sentColorsStyle() : this.receivedColorsStyle()
    },
    determineMessageColorsStructure () {
      return {
        sent: this.sentColorsStyle(true),
        received: this.receivedColorsStyle(true)
      }
    },
    triggerButtonPressed (button) {
      EventBus.$emit(Event.INPUT_HIDE_VALIDATION)
      this.onButtonPressed({
        type: 'button',
        data: button
      })
    },
    triggerQuickReplyPressed (quickReply) {
      this.onButtonPressed({
        type: 'quickReply',
        data: quickReply
      })
    }
  },
  computed: {
    authorName () {
      return this.user && this.user.name
    },
    chatImageUrl () {
      return (this.user && this.user.imageUrl) || this.chatIcon
    },
    messageClass () {
      return 'sc-message-bubble ' + (this.message.direction || '')
    },

    bubbleMetadata () {
      return this.message.metadata?.bubble || {}
    }
  }
}
</script>

<style lang="scss">
.sc-message {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  .sc-message--edited{
    opacity: 0.7;
    word-wrap: normal;
    font-size: xx-small;
    text-align: center;
  }

  .bubble-label {
    color: rgba(255, 255, 255, 0.5);
    text-transform: capitalize;
    font-size: 10px;
    padding-bottom: 3px;
  }
}

.sc-message--content {
  display: flex;
}

.sc-message--content.sent {
  margin-left: auto;
  width: calc(85% - 25px);
  justify-content: flex-end;
}

.sc-message--content.system {
  justify-content: center;
}

.sc-message--content.sent .sc-message--avatar {
  display: none;
}

.sc-message-error {
  margin-top: 2px;
  text-align: right;
  font-size: 70%;
  color: #DD3535;
}

.sc-message--avatar {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  align-self: flex-start;
  margin-right: 5px;
  margin-top: 5px;
}

.sc-message--meta {
  font-size: xx-small;
  margin-bottom: 0px;
  color: white;
  text-align: center;
}

@media (max-width: 420px) {
  .sc-message--content {
    width: 90%;
  }
}

.sc-message-bubble {
  border-radius: 6px;
}

.sc-message-bubble.sent {
  border-top-right-radius: 0;
}

.sc-message-bubble.received {
  border-top-left-radius: 0;
}

.sc-message--text {
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;

  p {
     margin: 0
  }

  &:hover .sc-message--toolbox{
    left: -20px;
    opacity: 1;
  }
  .sc-message--toolbox{
    transition: left 0.2s ease-out 0s;
    white-space: normal;
    opacity: 0;
    position: absolute;
    left: 0px;
    width: 25px;
    top: 0;
    button {
      background: none;
      border: none;
      padding: 0px;
      margin: 0px;
      outline: none;
      width:100%;
      text-align: center;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
}
.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #4e8cff;
  overflow-wrap: anywhere;
}

.sc-message--text code {
  font-family: 'Courier New', Courier, monospace !important;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
}

.tooltip {
  display: block !important;
  z-index: 10000;
  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }
  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }
  &[x-placement^="top"] {
    margin-bottom: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="bottom"] {
    margin-top: 5px;
    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="right"] {
    margin-left: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  &[x-placement^="left"] {
    margin-right: 5px;
    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }
  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
  &.info {
    $color: rgba(#004499, .9);
    .tooltip-inner {
      background: $color;
      color: white;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }
    .tooltip-arrow {
      border-color: $color;
    }
  }
  &.popover {
    $color: #f9f9f9;
    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }
    .popover-arrow {
      border-color: $color;
    }
  }
}
</style>
