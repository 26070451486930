export default Object.freeze({
  LAUNCHER_CLICK_OPEN: 'launcher_click_open',
  LAUNCHER_CLICK_CLOSE: 'launcher_click_close',
  HEADER_CLICK_CLOSE: 'header_click_close',
  FILE_SUBMIT: 'file_submit',
  MESSAGE_SUBMIT: 'message_submit',
  POP_UP_CLICK_OPEN: 'pop_up_click_open',
  POP_UP_CLICK_CLOSE: 'pop_up_click_close',
  POP_UP_BUTTON_CLICK: 'pop_up_button_click',
  POP_UP_QUICK_REPLY_CLICK: 'pop_up_quick_reply_click',
  BUTTON_CLICK: 'button_click',
  QUICK_REPLY_CLICK: 'quick_reply_click',
  CALL_OPERATOR: 'call_operator'
})
