import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Chat from './components/chat/index'
import VueI18n from 'vue-i18n'
import strings from './i18n/strings'
import Icon from 'vue-awesome/components/Icon'
import '@fontsource/open-sans/300.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/600.css'
import './styles/main.scss'

Vue.component('v-icon', Icon)

Vue.config.productionTip = false
Vue.use(Chat, {})
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: strings
})

new Vue({
  router,
  render: h => h(App),
  i18n
}).$mount('#app')
