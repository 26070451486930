<template>
  <div class="sc-typing-indicator"
       :class="{ 'indent': indent }"
       :style="messageColors"
  >
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>
<script>

export default {
  props: {
    messageColors: {
      type: Object,
      required: true
    },
    indent: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.sc-typing-indicator {
  text-align: center;
  padding: 10px 15px;
  border-top-left-radius: 0;
}

.indent {
  margin-left: 30px;
}

.sc-typing-indicator span {
  display: inline-block;
  background-color: #B6B5BA;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 3px;
  animation: bob 2s infinite;
}

/* SAFARI GLITCH */
.sc-typing-indicator span:nth-child(1) {
  animation-delay: -1s;
}
.sc-typing-indicator span:nth-child(2) {
  animation-delay: -0.85s;
}
.sc-typing-indicator span:nth-child(3) {
  animation-delay: -0.7s;
}

@keyframes bob {
  10% {
    transform: translateY(-10px);
    background-color: #9E9DA2;
  }
  50% {
    transform: translateY(0);
    background-color: #B6B5BA;
  }
}
</style>
