import Vue from 'vue'

// we're getting a Vue instance that’s entirely decoupled from the DOM or the rest of our app
// and we'll just use its $emit and $on methods to handle the events
const EventBus = new Vue()
const Event = Object.freeze({
  CHAT_ONLINE: 'chat:online',
  CHAT_OFFLINE: 'chat:offline',
  CHAT_SCROLL_TOP: 'chat:scroll-to-top',
  CHAT_SCROLL_BOTTOM: 'chat:scroll-to-bottom',
  CHAT_UNREAD_MESSAGES: 'chat:unread-messages',
  CHAT_OPENED: 'chat:opened',
  CHAT_CLOSED: 'chat:closed',
  IMAGE_EXPANDED: 'image:expanded',
  INPUT_FOCUS: 'input:focus',
  STATUS_BAR_CLICK: 'status-bar:click',
  DICTATION_UPDATE: 'dictation:update',
  LAUNCHER_DIMENSIONS: 'launcher:dimensions',
  INPUT_SHOW_VALIDATION: 'input:show-validation',
  INPUT_HIDE_VALIDATION: 'input:hide-validation'
})
export { EventBus, Event }
