<template>
  <div>
    <div v-if="file" class='file-container'
         :style="{backgroundColor: colors.userInput.text, color: colors.userInput.bg}">
      <span class='icon-file-message'>
        <img :src="icons.file.img" :alt="icons.file.name" height="15"/>
      </span>
      {{ fileName }}
      <span class='delete-file-message' @click="cancelFile()">
        <v-icon name="times" :title="$t('interface.removeFile')" :style="{color: colors.userInput.bg}"/>
      </span>
      <div v-if="fileTooLarge">
        ⚠️ {{ $t('interface.maxFileSize') }}
      </div>
    </div>
    <form class="sc-user-input-wrapper"
          :class="{ active: inputActive, 'powered-by-amio': hasPoweredByAmio }"
          :style="{ background: colors.userInput.wrapperBg }"
    >
      <div class="sc-user-input" :style="{background: colors.userInput.bg}">
        <div
          role="button"
          tabIndex="0"
          @focus="setInputActive(true)"
          @blur="setInputActive(false)"
          @keydown.enter.exact.prevent="handleSubmit"
          @paste="handlePaste"
          @input="containsInputText = !!$event.target.textContent"
          :contentEditable="!isVoiceUploadLoading && !isAudioRecording"
          :placeholder="$t('interface.writeMessage')"
          class="sc-user-input--text"
          ref="userInput"
          :style="{color: colors.userInput.text}"
        />
        <div class="sc-user-input--buttons">
          <div v-if="showEmoji" class="sc-user-input--button">
            <EmojiIcon :onEmojiPicked="_handleEmojiPicked" :color="colors.userInput.text"/>
          </div>
          <user-input-button v-if="containsInputText && !isAudioRecording"
                             :icon-color="colors.userInput.text"
                             tooltip="Clear"
                             icon-offset-y="4px"
                             @click.prevent="clearInput"
          >
            <icon-clear/>
          </user-input-button>
          <div v-else-if="showFile && !isAudioRecording" class="sc-user-input--button">
            <file-icons :onChange="_handleFileSubmit"
                        :color="colors.userInput.text"
                        :title="$t('interface.attachFile')"/>
          </div>
          <div v-else class="sc-user-input--button">
            <icon-sound-wave/>
          </div>
        </div>
      </div>
      <user-input-control :audio-recording="isAudioRecording"
                          :colors="colors"
                          :contains-input-text="containsInputText"
                          :submit-disabled="submitDisabled"
                          :show-voice="showVoice"
                          :is-sound-detected="isSoundDetected"
                          :is-button-disabled="isButtonDisabled"
                          :is-microphone-access-denied="isMicrophoneAccessDenied"
                          @submit="handleSubmit"
      />
    </form>
  </div>
</template>

<script>
import EmojiIcon from './icons/EmojiIcon.vue'
import FileIcons from './icons/FileIcons.vue'
import UserInputButton from './UserInputButton.vue'
import FileIcon from './assets/file.svg'
import stringUtils from '../../util/string-utils'
import { EventBus, Event } from '@/util/event-bus'
import IconClear from './icons/icons/IconClear'
import 'vue-awesome/icons/times'
import UserInputControl from '@/components/chat/UserInputControl'
import { ANIMATION_TIME } from '@/util/constants'
import IconSoundWave from '@/components/chat/icons/icons/IconSoundWave'
import VoiceService from '@/util/voice.service'

const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20MB

export default {

  components: {
    IconSoundWave,
    UserInputControl,
    UserInputButton,
    EmojiIcon,
    FileIcons,
    IconClear
  },
  props: {
    icons: {
      type: Object,
      required: false,
      default: function () {
        return {
          file: {
            img: FileIcon,
            name: 'default'
          }
        }
      }
    },
    showEmoji: {
      type: Boolean,
      default: () => false
    },
    showFile: {
      type: Boolean,
      default: () => false
    },
    showVoice: {
      type: Boolean,
      default: false
    },
    onSubmit: {
      type: Function,
      required: true
    },
    onButtonPressed: {
      type: Function,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Write something...'
    },
    colors: {
      type: Object,
      required: true
    },
    hasPoweredByAmio: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      file: null,
      inputActive: false,
      online: false,
      audioRecorder: null,
      containsInputText: false,
      voiceService: null
    }
  },
  methods: {
    cancelFile () {
      this.file = null
    },
    setInputActive (onoff) {
      this.inputActive = onoff
    },
    focusUserInput () {
      if (this.$refs.userInput) this.$refs.userInput.focus()
    },

    /**
     * HACK: since the chat is using div with contenteditable instead of input/textarea,
     * it would insert formatted html when something is pasted. This function extracts
     * only text from whatever was pasted. The reason we didn't just use input/textarea
     * instead of the div is that it's resized automatically when a long text is written,
     * which is hard to achieve with textarea
     */
    handlePaste (e) {
      e.preventDefault()
      let text = ''
      if (e.clipboardData || e.originalEvent.clipboardData) {
        text = (e.originalEvent || e).clipboardData.getData('text/plain')
      } else if (window.clipboardData) {
        text = window.clipboardData.getData('Text')
      }

      const command = document.queryCommandSupported('insertText') ? 'insertText' : 'paste'
      document.execCommand(command, false, text)
    },
    submitMessage (event) {
      if (this.submitDisabled) return

      const text = this.$refs.userInput.innerText.trim()
      const file = this.file
      if (file) {
        this._submitTextWhenFile(event, text, file)
      } else {
        if (text && text.length > 0) {
          this.onSubmit(this.createClientMessage('text', { text }))
          this.$refs.userInput.innerHTML = ''
        }
      }
      this.containsInputText = false
    },
    _submitTextWhenFile (event, text, file) {
      this.onSubmit(this.createClientMessage('file', {
        file,
        url: null
      }))
      this.file = null

      if (text && text.length > 0) {
        this.onSubmit(this.createClientMessage('text', { text }))
        this.$refs.userInput.innerHTML = ''
      }
    },
    _handleEmojiPicked (emoji) {
      this.onSubmit({
        author: 'client',
        direction: 'sent',
        type: 'emoji',
        data: { emoji }
      })
    },
    _handleFileSubmit (file) {
      this.file = file
    },
    createClientMessage (type, data) {
      return {
        author: 'client',
        direction: 'sent',
        type,
        data,
        error: null
      }
    },

    clearInput () {
      if (this.isVoiceUploadLoading) return

      this.$refs.userInput.textContent = ''
      this.focusUserInput()
      this.containsInputText = false
    },

    async handleSubmit (e) {
      if ((!this.containsInputText || this.isAudioRecording) && this.showVoice) {
        await this.voiceService.handleAudioRecording()
        return
      }

      if (this.isVoiceUploadLoading) return

      this.submitMessage(e)
    },

    setMessage (text) {
      this.containsInputText = !!text
      if (!text) return

      this.$refs.userInput.textContent = text
      this.focusUserInput()
    }
  },

  computed: {
    isVoiceUploadLoading () {
      return this.voiceService?.voiceUploadLoading
    },

    isContentEditable () {
      return !this.isVoiceUploadLoading && !this.isAudioRecording
    },

    isAudioRecording () {
      return this.voiceService?.audioRecording
    },

    isMicrophoneAccessDenied () {
      return this.voiceService?.isMicrophoneAccessDenied
    },

    isSoundDetected () {
      return this.voiceService?.isSoundDetected
    },

    isButtonDisabled () {
      return this.isVoiceUploadLoading && !this.isAudioRecording
    },

    fileTooLarge () {
      return !!(this.file && this.file.size > MAX_FILE_SIZE)
    },

    submitDisabled () {
      return this.fileTooLarge || !this.online
    },

    fileName () {
      return stringUtils.ellipsis(this.file.name, 30)
    }
  },

  mounted () {
    EventBus.$on(Event.INPUT_FOCUS, () => {
      setTimeout(() => this.focusUserInput(), ANIMATION_TIME)
    })
    EventBus.$on(Event.CHAT_ONLINE, () => {
      this.online = true
    })
    EventBus.$on(Event.CHAT_OFFLINE, () => {
      this.online = false
    })
    EventBus.$on(Event.DICTATION_UPDATE, dictation => this.setMessage(dictation))
  },

  created () {
    try {
      this.voiceService = new VoiceService(navigator)
    } catch (e) {
      this.voiceService = null
    }
  }
}
</script>

<style lang="scss">
.sc-user-input {
  min-height: 40px;
  margin: 0;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #f4f7f9;
  border-radius: 24px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
}

.sc-user-input--text {
  flex: 1;
  resize: none;
  border: none;
  outline: none;
  border-bottom-left-radius: 6px;
  box-sizing: border-box;
  padding: 10px 10px 10px 16px;
  font-size: 16px; /* IMPORTANT: must be at least 16px, otherwise mobile Safari will zoom in when input is focused */
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  color: #565867;
  -webkit-font-smoothing: antialiased;
  max-height: 200px;
  overflow: scroll;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.sc-user-input--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  /* color: rgba(86, 88, 103, 0.3); */
  filter: contrast(15%);
  outline: none;
  cursor: text;
}

.sc-user-input-wrapper {
  display: flex;
  padding: 20px 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  column-gap: 16px;

  &.powered-by-amio {
    padding: 16px !important;
  }
}

.sc-user-input--buttons {
  display: inline-flex;
  justify-content: flex-end;
  padding-right: 16px;
}

.sc-user-input--button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.sc-user-input.active {
  background-color: white;
  box-shadow: 0 -5px 20px 0 rgba(150, 165, 190, 0.2);
}

.sc-user-input--button label:hover path {
  fill: rgba(86, 88, 103, 1);
}

.file-container {
  background-color: #f4f7f9;
  padding: 5px 20px;
  color: #565867;
}

.delete-file-message {
  font-style: normal;
  float: right;
  cursor: pointer;
  color: #c8cad0;
}

.delete-file-message:hover {
  color: #5d5e6d;
}

.icon-file-message {
  margin-right: 5px;
}
</style>
