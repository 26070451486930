var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"launcher",staticClass:"sc-launcher",class:[
       _vm.animationClass,
       {
         open: _vm.isOpen,
         closed: !_vm.isOpen,
         wide: _vm.isWideLauncher
       }
     ],style:(_vm.launcherStyle),on:{"click":function($event){$event.preventDefault();_vm.isOpen ? _vm.close() : _vm.open()}}},[(_vm.newMessagesCount > 0 && !_vm.isOpen)?_c('div',{staticClass:"sc-new-messsages-count",style:({background: _vm.colors.launcher.newMessagesCountBg || '#ff4646'})},[_vm._v(" "+_vm._s(_vm.newMessagesCount)+" ")]):_vm._e(),(_vm.isOpen)?_c('icon-cross',{staticClass:"sc-closed-icon",attrs:{"fill":_vm.colors.launcher.closeIconColor || '#fff'}}):_vm._e(),_c('div',{staticClass:"sc-icon-wrapper",class:{ wide: _vm.isWideLauncher }},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isOpen),expression:"!isOpen"}],ref:"buttonIcon",staticClass:"sc-open-icon",attrs:{"src":_vm.launcherImageUrl || _vm.icons.open.img,"alt":_vm.icons.open.name}}),(_vm.isWideLauncher)?_c('p',{ref:"buttonTitle",staticClass:"sc-button-text",style:({
         '--margin-bottom': _vm.colors.launcher.marginBottom || 'auto',
         'font-weight': _vm.colors.launcher.fontWeight || 600
       })},[_vm._v(" "+_vm._s(_vm.$t('buttonTitle'))+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }