<template>
  <div class="chat">
    <chat-widget
      :alwaysScrollToBottom="false"
      :close="closeChat"
      :colors="colorTheme"
      :isOpen="isChatOpen"
      :messageList="messageList"
      :messageStyling="messageStyling"
      :newMessagesCount="newMessagesCount"
      :onMessageWasSent="onMessageWasSent"
      :onButtonPressed="onButtonPressed"
      :open="openChat"
      :participants="participants"
      :showCloseButton="!chatConfig.hideCloseButton"
      :showHeader="!chatConfig.hideHeader"
      :showLauncher="showLauncher"
      :showEmoji="false"
      :showFile="true"
      :showVoice="chatConfig.showVoice"
      :showTypingIndicator="showTypingIndicator"
      :powered-by-amio-config="{enabled: !chatConfig.disableAmioLink, utmSource: chatConfig.theme}"
      :isPopUpOpen="isPopUpOpen"
      :titleImageUrl="chatConfig.logoUrl"
      :launcherImageUrl="chatConfig.launcherImageUrl"
      :title="$t('interface.welcome')"
      :wideButton="chatConfig.wideButton"
      :forceFullScreen="chatConfig.forceFullScreen"
      :hide-launcher="chatConfig.hideLauncher"
      :chatWindowPosition="chatConfig.chatWindowPosition"
      class="chat"
      @closePopUp="closePopUp(USER_EVENT.POP_UP_CLICK_CLOSE)"
      @onType="handleOnType"
      @edit="editMessage"
      @remove="removeMessage"
    />
  </div>
</template>

<script>
import { amioChat } from 'amio-chat-sdk-web'
import adapter from '../util/adapter'
import chatColors from '../util/chat-colors'
import { EventBus, Event } from '../util/event-bus'
import USER_EVENT from '../util/user-event.constants'
import clone from 'clone'
import { sleep } from '@/util/mixins'

export default {
  data () {
    return {
      participants: [],
      messageList: [],
      newMessagesCount: 0,
      isChatOpen: false,
      isPopUpOpen: false,
      showTypingIndicator: false,
      alwaysScrollToBottom: true,
      messageStyling: false,
      userIsTyping: false,
      colorTheme: chatColors.blue,
      chatClient: amioChat,
      online: false,
      storage: null,
      localStorageSessionName: 'amio_chat_session',
      historyCursor: null,
      historyEnd: false,
      historyCount: 10,
      historyLoadingInProgress: false,
      firstOpen: true,
      chatConfig: {},
      metadata: {},
      USER_EVENT
    }
  },
  computed: {
    showLauncher () {
      switch (this.chatConfig.hideLauncher) {
        case 'always': return false
        case 'chatOpen': return !this.isChatOpen
        default: return true
      }
    }
  },
  methods: {
    async openChat (userEventName = null, popUpMessage = null) {
      if (this.firstOpen) {
        await this.loadHistory(20)
        // send GET_STARTED notification if this is a new contact (has empty history)
        if (this.messageList.length === 0) {
          this.chatClient.notifications.send({ event: 'GET_STARTED_BUTTON', metadata: this.metadata })
        }
        this.firstOpen = false
      }
      this.newMessagesCount = 0
      if (this.isPopUpOpen) {
        // In chrome the parent div was updating slower (when clicking on pop up quick reply) than the chat window was resized,
        // which caused a visual glitch. The 10ms timeout fixes that.
        await sleep(10)
        this.closePopUp()
      }

      this.notifyParent({ chatState: 'open' })

      if (userEventName) {
        const userEventParams = popUpMessage ? {
          type: popUpMessage.type,
          payload: popUpMessage.data.text,
          metadata: this.resolveMetadata(popUpMessage)
        } : null
        this.notifyParent({ userEvent: this.createUserEvent(userEventName, userEventParams) })
      }
      this.chatClient.notifications.send({ event: 'CHAT_OPENED', metadata: this.metadata })

      // In chrome the parent div was updating slower than the chat window was resized,
      // which caused a visual glitch. The 10ms timeout fixes that.
      setTimeout(() => {
        this.isChatOpen = true
        EventBus.$emit(Event.INPUT_FOCUS)
      }, 10)
    },
    closeChat (userEventName = null) {
      this.isChatOpen = false
      this.notifyParent({ chatState: 'closed' })
      if (userEventName) {
        this.notifyParent({ userEvent: this.createUserEvent(userEventName) })
      }
    },
    imageExpand (expanded) {
      this.notifyParent({ chatState: expanded ? 'imageExpand' : 'open' })
    },
    notifyParent (message) {
      window.parent.postMessage(message, '*')
    },
    onMessageWasSent (message) {
      this.addToMessageList(message)
      const userEventParams = { type: message.type, metadata: this.resolveMetadata(message) }

      if (message.type === 'text') {
        this.sendTextMessage(message)
        userEventParams.payload = message.data.text
        this.notifyParent({ userEvent: this.createUserEvent(USER_EVENT.MESSAGE_SUBMIT, userEventParams) })
      } else if (message.type === 'file') {
        this.sendFile(message)
        userEventParams.payload = message.data.file.name
        this.notifyParent({ userEvent: this.createUserEvent(USER_EVENT.FILE_SUBMIT, userEventParams) })
      }
    },
    async onButtonPressed (button) {
      const userEventParams = {
        type: button.type,
        payloadType: button.data.type,
        title: button.data.title,
        payload: button.data.payload,
        metadata: this.metadata
      }

      if (button.type === 'button' && ['url', 'phone', 'callback'].includes(button.data?.type)) {
        if (this.isPopUpOpen) {
          this.newMessagesCount = 0
          this.closePopUp()
          if (button.type === 'button' && button.data.type === 'callback') {
            this.notifyParent({ callback: button.data.payload })
          }
          this.notifyParent({ userEvent: this.createUserEvent(USER_EVENT.POP_UP_BUTTON_CLICK, userEventParams) })
          return
        }

        this.notifyParent({ userEvent: this.createUserEvent(USER_EVENT.BUTTON_CLICK, userEventParams) })
      }

      const message = this.createSentMessage({ text: button.data.title })
      try {
        if (button.type === 'quickReply') {
          this.addToMessageList(message)
          const userEventName = this.isPopUpOpen ? USER_EVENT.POP_UP_QUICK_REPLY_CLICK : USER_EVENT.QUICK_REPLY_CLICK
          this.notifyParent({ userEvent: this.createUserEvent(userEventName, userEventParams) })
          await this.sendQuickReply(button.data)
        }

        if (button.type === 'button' && button.data.type === 'postback') {
          this.addToMessageList(message)
          const userEventName = this.isPopUpOpen ? USER_EVENT.POP_UP_BUTTON_CLICK : USER_EVENT.BUTTON_CLICK
          this.notifyParent({ userEvent: this.createUserEvent(userEventName, userEventParams) })
          await this.chatClient.postbacks.send(button.data.payload)
        }

        if (button.type === 'button' && button.data.type === 'callback') {
          this.addToMessageList(message)
          this.notifyParent({ callback: button.data.payload })
        }

        if (this.isPopUpOpen) {
          this.openChat()
        }
      } catch (e) {
        message.error = this.$t('error.messageSend')
      }
    },
    createUserEvent (name, params = null) {
      return params ? { name, params } : { name }
    },
    resolveMetadata (message) {
      return message.metadata ? { ...message.metadata, ...this.metadata } : this.metadata
    },
    addToMessageList (message) {
      this.messageList = this.messageList.concat([message])
    },
    handleOnType () { },
    editMessage () {},
    removeMessage () {},
    createSentMessage (data) {
      return {
        author: 'client',
        direction: 'sent',
        type: 'text',
        data,
        error: null
      }
    },
    setParticipants () {
      this.participants = [
        {
          id: 'client',
          name: 'User'
        },
        {
          id: 'bot',
          name: 'Bot',
          imageUrl: this.logoUrl
        }
      ]
    },

    resolveColors (theme) {
      return chatColors[theme]
    },

    /// ---- amio chat
    sendTextMessage (message) {
      this.chatClient.messages.sendText(message.data.text, this.resolveMetadata(message))
        .then((response) => {
          // console.log('send message success', response)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('send message error', err)
          message.error = this.$t('error.messageSend')
        })
    },

    sendFile (message) {
      const file = message.data.file
      const fr = new FileReader()
      fr.onerror = (e) => {
        // eslint-disable-next-line no-console
        console.error('File reader error:', e)
      }
      fr.onloadend = (e) => {
        const fr = e.target
        const fileArrayBuffer = fr.result

        this.chatClient.files.upload(file.name, file.type, fileArrayBuffer)
          .then((response) => {
            message.data.url = response.url
            this.chatClient.messages.sendFile(response.url, this.metadata)
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.error('Error while sending file:', err)
                message.error = this.$t('error.fileSend')
              })
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Error while uploading file:', err)
            message.error = err.error_code === 3
              ? this.$t('error.isNotSupported', [err.details.rejected_value])
              : this.$t('error.fileUpload')
          })
      }

      fr.readAsArrayBuffer(file)
    },
    sendQuickReply (quickReply) {
      return this.chatClient.messages.sendQuickReply(quickReply.title, quickReply.payload, this.metadata)
    },
    setOnline (online) {
      this.online = online
      EventBus.$emit(online ? Event.CHAT_ONLINE : Event.CHAT_OFFLINE)
    },
    closePopUp (userEventName = null) {
      this.isPopUpOpen = false
      this.notifyParent({ chatState: 'pop-up-closed' })
      if (userEventName) {
        this.notifyParent({ userEvent: this.createUserEvent(userEventName) })
      }
    },
    receiveMessage (data) {
      const msg = adapter.convertMessage(data)
      this.showTypingIndicator = false
      this.addToMessageList(msg)
      if (!this.isChatOpen) {
        this.newMessagesCount++
        if (!this.chatConfig.disablePopUpMessages) {
          this.isPopUpOpen = true
          this.notifyParent({ chatState: 'pop-up' })
        }
      }
    },
    receiveNotification (data) {
      if (data.type === 'typing_on') {
        this.showTypingIndicator = true
      }
      if (data.type === 'typing_off') {
        this.showTypingIndicator = false
      }
      if (data.type === 'custom' && data.payload.type === 'validation') {
        EventBus.$emit(Event.INPUT_SHOW_VALIDATION, data.payload)
      }
      if (data.type === 'custom' && data.payload.event === 'CALL_OPERATOR') {
        const userEventParams = { intent: data.payload.intent, context: data.payload.context, metadata: this.metadata }
        this.notifyParent({ userEvent: this.createUserEvent(USER_EVENT.CALL_OPERATOR, userEventParams) })
      }
    },

    async loadHistory (count) {
      if (this.historyLoadingInProgress || this.historyEnd) return

      this.historyLoadingInProgress = true
      try {
        const history = await this.chatClient.messages.list(this.historyCursor, count)
        this.historyLoaded(history)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error loading history', e)
      } finally {
        this.historyLoadingInProgress = false
      }
    },
    historyLoaded (history) {
      this.historyCursor = history.cursor.next
      this.historyEnd = !history.cursor.has_next
      const loadedMsgs = history.messages.reverse().map((el) => adapter.convertMessage(el))
      this.messageList = loadedMsgs.concat(this.messageList)
    },
    onScrollUp () {
      this.loadHistory(this.historyCount)
    },
    processQueryParams () {
      const config = clone(this.$route.query)
      const booleanQueryParams = ['wideButton', 'showVoice', 'disablePopUpMessages', 'hideCloseButton', 'forceFullScreen', 'hideHeader', 'disableAmioLink']
      booleanQueryParams.forEach(param => {
        config[param] = config[param] === 'true'
      })
      // other processing can be done here, e.g. default values, type conversions...
      if (!config.lang) config.lang = 'en'
      if (!config.theme) config.theme = 'blue'
      if (!config.storageType) config.storageType = 'local'
      if (!config.hideLauncher) config.hideLauncher = 'never'
      if (!config.chatWindowPosition) config.chatWindowPosition = 'top'

      return config
    },

    receiveDictationResult (message) {
      EventBus.$emit(Event.DICTATION_UPDATE, message)
    },

    setLauncherDimensions (launcherWidth, launcherHeight) {
      this.notifyParent({ launcherWidth, launcherHeight })
    }
  },

  mounted () {
    this.chatConfig = this.processQueryParams()

    window.addEventListener('message', async (event) => {
      if (event.data && 'isChatOpen' in event.data) {
        event.data.isChatOpen ? this.openChat() : this.closeChat()
      }

      if (event.data && 'eventId' in event.data) {
        await this.chatClient.postbacks.send(event.data.eventId)
      }

      if (event.data && 'metadata' in event.data) {
        // merge event.data.metadata into this.metadata
        this.metadata = { ...this.metadata, ...event.data.metadata }
      }
    })

    this.$i18n.locale = this.chatConfig.lang
    this.colorTheme = this.resolveColors(this.chatConfig.theme)

    this.setParticipants()

    const config = {
      channelId: this.chatConfig.channelId,
      storageType: this.chatConfig.storageType
    }
    if (process.env.VUE_APP_AMIO_WEBCHAT_SERVER_URL) {
      config._amioChatServerUrl = process.env.VUE_APP_AMIO_WEBCHAT_SERVER_URL
    }
    if (this.chatConfig.externalContactId) {
      config.externalContactId = this.chatConfig.externalContactId
    }

    this.chatClient.events.onMessageReceived(this.receiveMessage)
    this.chatClient.events.onMessageEcho(this.receiveMessage)
    this.chatClient.events.onConnectionStateChanged(this.setOnline)
    this.chatClient.events.onNotificationReceived(this.receiveNotification)
    this.chatClient.events.onDictationResultReceived(this.receiveDictationResult)
    this.chatClient.connect(config).then(() => {
      if (this.chatConfig.startOpened === 'true') {
        this.openChat()
      }
    })

    EventBus.$on(Event.CHAT_SCROLL_TOP, this.onScrollUp)
    EventBus.$on(Event.IMAGE_EXPANDED, this.imageExpand)
    EventBus.$on(Event.LAUNCHER_DIMENSIONS, this.setLauncherDimensions)

    // show chat offline status if we're unable to connect after chat is loaded
    setTimeout(() => {
      if (!this.online) EventBus.$emit(Event.CHAT_OFFLINE)
    }, 2000)

    this.notifyParent({ chatState: 'mounted' })
  }

}
</script>

<style scoped>
.chat {
  height: 100%;
  overflow: hidden;
}
</style>
