<template>
  <div class="sc-message-list" ref="scrollList" :style="getMessageListStyle()" @scroll="handleScroll">
    <message v-for="(message, idx) in messages"
             :message="message"
             :isLastMessage="isLastMessage(idx)"
             :user="profile(message.author)"
             :key="idx" :colors="colors"
             :messageStyling="messageStyling"
             @remove="$emit('remove', message)"
             :onButtonPressed="onButtonPressed"
             :onSubmit="onSubmit"
    >
      <template v-slot:user-avatar="scopedProps">
        <slot name="user-avatar" :user="scopedProps.user" :message="scopedProps.message">
        </slot>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <slot name="text-message-body" :message="scopedProps.message" :messageText="scopedProps.messageText" :messageColors="scopedProps.messageColors" :me="scopedProps.me">
        </slot>
      </template>
      <template v-slot:text-message-toolbox="scopedProps">
        <slot name="text-message-toolbox" :message="scopedProps.message" :me="scopedProps.me">
        </slot>
      </template>
    </message>
    <message v-show="showTypingIndicator" :message="{type: 'typing'}" :user="profile('bot')" :colors="colors" :messageStyling="messageStyling" :onButtonPressed="onButtonPressed"/>
    <ValidatedInput v-if="displayValidatedInput"
                    :colors="colors"
                    :onSubmit="onSubmit"
                    :validation-params="validationParams"
                    class="fade-in"
    />
  </div>
</template>
<script>
import Message from './Message.vue'
import chatIcon from './assets/chat-icon.svg'
import { EventBus, Event } from '@/util/event-bus'
import ValidatedInput from '@/components/chat/messages/ValidatedInput.vue'

export default {
  components: {
    ValidatedInput,
    Message
  },
  props: {
    participants: {
      type: Array,
      required: true
    },
    messages: {
      type: Array,
      required: true
    },
    showTypingIndicator: {
      type: Boolean,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    alwaysScrollToBottom: {
      type: Boolean,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    },
    onButtonPressed: {
      type: Function,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      plsScrollDown: false,
      scrollThreshold: 300,
      validationParams: null,
      displayValidatedInput: false
    }
  },
  methods: {
    scrollDown () {
      this.$refs.scrollList.scroll({
        top: this.$refs.scrollList.scrollHeight,
        left: 0
      })
      this.plsScrollDown = false
    },
    handleScroll (e) {
      if (e.target.scrollTop === 0) {
        EventBus.$emit(Event.CHAT_SCROLL_TOP)
      }

      if (this.isNearBottom(e.target)) {
        EventBus.$emit(Event.CHAT_SCROLL_BOTTOM)
      }
    },
    profile (author) {
      const profile = this.participants.find(profile => profile.id === author)

      // A profile may not be found for system messages or messages by 'me'
      return profile || { imageUrl: '', name: '' }
    },
    isLastMessage (index) {
      return index === this.messages.length - 1
    },
    isNearBottom (el, threshold = 0) {
      return el.scrollHeight - el.scrollTop <= el.clientHeight + threshold
    },
    scrollDownDeferred () {
      // this.$nextTick doesn't scroll all the way down, it seems like there needs to be more time before the scroll height is set properly
      setTimeout(() => this.scrollDown(), 5)
    },
    onStatusBarClick (type) {
      if (type === 'unreadMessages') {
        this.scrollDown()
      }
    },
    showValidatedInput (validationData) {
      this.validationParams = validationData.validation_params
      this.displayValidatedInput = true
    },
    hideValidatedInput () {
      this.validationParams = null
      this.displayValidatedInput = false
    },
    isMobileBrowser () {
      const mobileKeywords = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
      return mobileKeywords.some(keyword => navigator.userAgent.includes(keyword))
    },
    getMessageListStyle () {
      const style = {
        background: this.colors.messageList.bg
      }
      if (this.isMobileBrowser()) {
        style.display = 'grid'
        style.gridTemplateRows = '1fr auto'
      }
      return style
    }
  },
  computed: {
    defaultChatIcon () {
      return chatIcon
    }
  },
  watch: {
    messages (newMessages, oldMessages) {
      if (oldMessages.length === 0) {
        // we usually get here when the chat is closed which means scroll height would be set to 0 at this time
        // to get around it we use this flag to signal that we want to scroll down once it's possible
        this.plsScrollDown = true
        return
      }
      if (newMessages[0].id !== oldMessages[0].id) {
        // we loaded new messages and added them to the top of the list, so now we have to scroll down a bit
        // to avoid triggering another loading
        this.$refs.scrollList.scrollTop += 300 // magic number, looks good in the UI
        return
      }
      if (!newMessages[newMessages.length - 1].id) {
        // there was a new sent message
        this.scrollDownDeferred()

        return
      }
      if (newMessages[newMessages.length - 1].id !== oldMessages[oldMessages.length - 1].id && this.isNearBottom(this.$refs.scrollList, this.scrollThreshold)) {
        // scroll down if we received a new message and we're near the bottom of the chat window
        // don't ask why scrollDown() doesn't work here coz I don't know either
        this.plsScrollDown = true
        return
      }

      // we got new message but we didn't scroll - show notification
      if (this.$refs.scrollList.scrollHeight > this.scrollThreshold) {
        EventBus.$emit(Event.CHAT_UNREAD_MESSAGES)
      }
    },
    showTypingIndicator (newValue) {
      if (newValue && this.isNearBottom(this.$refs.scrollList, this.scrollThreshold)) {
        this.plsScrollDown = true
      }
    }
  },

  mounted () {
    this.scrollDownDeferred()
    EventBus.$on(Event.STATUS_BAR_CLICK, this.onStatusBarClick)
    EventBus.$on(Event.INPUT_SHOW_VALIDATION, this.showValidatedInput)
    EventBus.$on(Event.INPUT_HIDE_VALIDATION, this.hideValidatedInput)
  },

  updated () {
    if (this.plsScrollDown && this.$refs.scrollList.scrollHeight) {
      this.scrollDownDeferred()
    }
  }
}
</script>

<style scoped lang="scss">
.sc-message-list {
  height: 100%;
  overflow: auto;
  background-size: 100%;
  padding: 20px;
}

.sc-overlay {
  width: 100%;
  z-index: 10;
  height: 20px;
}

.sc-pointer {
  cursor: pointer;
}
</style>
