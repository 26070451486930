<template>
  <a class="powered-by" :href="`https://amio.io/?utm_source=${utmSource}&utm_medium=referral&utm_campaign=powered-by-amio`" target="_blank">
    <img :src="highVoltage" alt="powered by amio" class="powered-by__icon">
    Powered by<span class="powered-by__amio" :style="`color:${amioColor}`" v-html="'&nbsp;Amio'"/>
  </a>
</template>

<script>
import highVoltage from './assets/high-voltage.png'

export default {
  name: 'PoweredByAmio',

  props: {
    amioColor: {
      type: String,
      required: true
    },
    utmSource: {
      type: String
    }
  },

  data () {
    return {
      highVoltage
    }
  }
}
</script>

<style scoped lang="scss">
.powered-by {
  display: flex;
  justify-content: center;
  height: 20px;
  padding: 0 0 16px 0;
  margin: auto;
  text-decoration: none;
  color: #B6C4D8;
  font-size: 12px;

  &__icon {
    width: 14px;
    height: 14px;
    margin-right: 2px;
  }

  &__amio {
    opacity: 0.7;
    font-weight: bold;
  }
}
</style>
