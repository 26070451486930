<template>
  <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.293 1.29302C0.480528 1.10555 0.734836 1.00023 1 1.00023C1.26516 1.00023 1.51947 1.10555 1.707 1.29302L8 7.58602L14.293 1.29302C14.3852 1.19751 14.4956 1.12133 14.6176 1.06892C14.7396 1.01651 14.8708 0.988924 15.0036 0.98777C15.1364 0.986616 15.2681 1.01192 15.391 1.0622C15.5138 1.11248 15.6255 1.18673 15.7194 1.28063C15.8133 1.37452 15.8875 1.48617 15.9378 1.60907C15.9881 1.73196 16.0134 1.86364 16.0123 1.99642C16.0111 2.1292 15.9835 2.26042 15.9311 2.38242C15.8787 2.50443 15.8025 2.61477 15.707 2.70702L8.707 9.70702C8.51947 9.89449 8.26516 9.99981 8 9.99981C7.73484 9.99981 7.48053 9.89449 7.293 9.70702L0.293 2.70702C0.105529 2.51949 0.000213623 2.26518 0.000213623 2.00002C0.000213623 1.73486 0.105529 1.48055 0.293 1.29302Z"
      :fill="fill"/>
  </svg>

</template>
<script>
export default {
  props: {
    fill: {
      type: String
    }
  }
}
</script>
