<template>
  <div class="sc-user-input--button">
    <user-input-button class="sc-user-input--button-control"
                       :class="classes"
                       :icon-color="colors.sentMessage.text"
                       :tooltip="controlButtonProps.tooltip"
                       :disabled="submitDisabled"
                       :style="controlButtonProps.style"
                       :icon-offset-x="controlButtonProps.x"
                       :icon-offset-y="controlButtonProps.y"
                       :icon-width="controlButtonProps.width"
                       :icon-height="controlButtonProps.height"
                       @click.prevent="handleSubmit"
    >
      <icon-spinner v-if="isButtonDisabled && !audioRecording"/>
      <icon-send v-else-if="displaySendIcon"/>
      <template v-else>
        <icon-mic v-if="!audioRecording"/>
        <icon-square v-else/>
      </template>
    </user-input-button>
  </div>
</template>

<script>
import IconMic from '@/components/chat/icons/icons/IconMic'
import IconSquare from '@/components/chat/icons/icons/IconSquare'
import IconSend from '@/components/chat/icons/icons/IconSend'
import UserInputButton from '@/components/chat/UserInputButton'
import IconSpinner from '@/components/chat/icons/icons/IconSpinner'

export default {
  name: 'UserInputControl',

  components: {
    IconSpinner,
    IconMic,
    IconSquare,
    IconSend,
    UserInputButton
  },

  props: {
    audioRecording: {
      type: Boolean,
      default: false
    },
    colors: {
      type: Object,
      default: () => {
      }
    },
    containsInputText: {
      type: Boolean,
      default: false
    },
    submitDisabled: {
      type: Boolean,
      default: false
    },
    showVoice: {
      type: Boolean,
      default: false
    },
    isSoundDetected: {
      type: Boolean,
      default: false
    },
    isButtonDisabled: {
      type: Boolean,
      default: false
    },
    isMicrophoneAccessDenied: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [
        this.isButtonDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
        { 'shadow-pulse-grow': this.audioRecording && this.isSoundDetected }
      ]
    },

    displaySendIcon () {
      return this.isMicrophoneAccessDenied || (this.containsInputText && !this.audioRecording) || !this.showVoice
    },

    controlButtonProps () {
      const displayRecordIcon = this.showVoice && !this.containsInputText && !this.audioRecording && !this.isMicrophoneAccessDenied
      if (displayRecordIcon) {
        return {
          x: this.isButtonDisabled ? '0px' : '-2px',
          y: '0px',
          tooltip: this.$t('interface.record'),
          style: {
            background: this.colors.sentMessage.bg
          }
        }
      }

      if (this.displaySendIcon) {
        return {
          x: '0px',
          y: '-2px',
          tooltip: this.$t('interface.send'),
          style: { background: this.colors.sentMessage.bg }
        }
      }

      return {
        x: this.isButtonDisabled ? '0px' : '4px',
        y: this.isButtonDisabled ? '0px' : '4px',
        width: 18,
        height: 18,
        tooltip: this.$t('interface.stopRecording'),
        style: {
          background: '#EB5757'
        }
      }
    }
  },

  methods: {
    handleSubmit () {
      if (this.isButtonDisabled) return

      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss">
.sc-user-input--button-control {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
