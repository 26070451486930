const defaultFont = "'Helvetica Neue', Helvetica, Arial, sans-serif"

export default {
  red: {
    header: {
      bg: '#D32F2F',
      fontSize: '20px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#D32F2F'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#F44336',
      text: '#fff',
      button: {
        bg: '#F44336',
        bgHover: 'rgba(244, 67, 54, 0.85)',
        border: 'none',
        text: '#fff'
      },
      qr: {
        borderColor: '#F44336',
        text: '#F44336',
        bgHover: '#F44336',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(244, 67, 54, 0.15)',
        text: '#F44336',
        border: '#F44336'
      }
    },
    receivedMessage: {
      bg: '#eaeaea',
      marginRight: '25px',
      text: '#222222',
      file: {
        bg: '#eaeaea',
        text: '#222222',
        border: '#222222'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f4f7f9',
      text: '#212121',
      sendIcon: '#D32F2F'
    },
    statusInfo: {
      bg: '#78aff7',
      text: '#fff'
    },
    font: defaultFont
  },
  green: {
    header: {
      bg: '#388E3C',
      fontSize: '20px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#388E3C'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#4CAF50',
      text: '#fff',
      button: {
        bg: '#4CAF50',
        bgHover: 'rgba(76, 175, 80, 0.85)',
        border: 'none',
        text: '#fff'
      },
      qr: {
        borderColor: '#4CAF50',
        text: '#4CAF50',
        bgHover: '#4CAF50',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(76, 175, 80, 0.15)',
        text: '#4CAF50',
        border: '#4CAF50'
      }
    },
    receivedMessage: {
      bg: '#eaeaea',
      marginRight: '25px',
      text: '#222222',
      file: {
        bg: '#eaeaea',
        text: '#222222',
        border: '#222222'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#fff',
      text: '#212121',
      sendIcon: '#388E3C'
    },
    statusInfo: {
      bg: '#78aff7',
      text: '#fff'
    },
    font: defaultFont
  },
  blue: {
    header: {
      bg: '#4e8cff',
      fontSize: '20px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#4e8cff'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#4e8cff',
      text: '#fff',
      button: {
        bg: '#4e8cff',
        bgHover: 'rgba(78, 140, 255, 0.85)',
        border: 'none',
        text: '#fff'
      },
      qr: {
        borderColor: '#4e8cff',
        text: '#4e8cff',
        bgHover: '#4e8cff',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(78, 140, 255, 0.15)',
        text: '#4e8cff',
        border: '#4e8cff'
      }
    },
    receivedMessage: {
      bg: '#eaeaea',
      marginRight: '25px',
      text: '#222222',
      file: {
        bg: '#eaeaea',
        text: '#222222',
        border: '#222222'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f4f7f9',
      text: '#565867',
      sendIcon: '#4e8cff'
    },
    statusInfo: {
      bg: '#78aff7',
      text: '#fff'
    },
    font: defaultFont
  },
  amio: {
    header: {
      bg: 'linear-gradient(70.77deg, #6e40ff 38%, #2f8fff)',
      fontSize: '20px',
      text: '#fff',
      padding: '8px',
      logoOnly: false
    },
    launcher: {
      bg: 'linear-gradient(70.77deg, #6e40ff 38%, #2f8fff)'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#6E40FF',
      text: '#fff',
      button: {
        bg: '#6E40FF',
        bgHover: 'rgba(110, 64, 255, 0.75)',
        border: 'none',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#6E40FF',
        text: '#6E40FF',
        bgHover: '#6E40FF',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(110, 64, 255, 0.15)',
        text: '#6E40FF',
        border: '#6E40FF'
      }
    },
    receivedMessage: {
      bg: '#eaeaea',
      marginRight: '25px',
      text: '#222222',
      file: {
        bg: '#eaeaea',
        text: '#222222',
        border: '#222222'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f4f7f9',
      text: '#565867',
      sendIcon: '#6E40FF'
    },
    statusInfo: {
      bg: '#9a80ee',
      text: '#fff'
    },
    font: 'Golos Text, sans-serif'
  },
  datart: {
    header: {
      bg: '#002e67',
      fontSize: '15px',
      text: '#fff',
      padding: '0px'
    },
    launcher: {
      bg: '#002e67',
      animation: true
    },
    messageBubble: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: 1.4,
      paddingText: '10px',
      paddingStruct: '10px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#002e67',
      text: '#fff',
      button: {
        bg: '#002e67',
        bgHover: 'rgba(0, 46, 103, 0.2)',
        border: '1px solid #002e67',
        text: '#fff',
        textHover: '#002e67'
      },
      qr: {
        borderColor: '#002e67',
        text: '#002e67',
        bgHover: 'rgba(0, 46, 103, 0.2)',
        borderColorHover: '#fff',
        textHover: '#002e67'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#002e67'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  footshop: {
    header: {
      bg: '#000',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: '#000',
      animation: true,
      marginBottom: '23px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      button: {
        bg: '#000',
        bgHover: '#4D4D4D',
        border: '1px solid #FFF',
        text: '#FFF',
        textHover: '#FFF'
      },
      qr: {
        borderColor: '#000',
        text: '#000',
        bgHover: '#000',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#4D4D4D',
      marginRight: '25px',
      text: '#FFF',
      file: {
        bg: '#F1F1F1',
        text: '#000',
        border: '#000'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'neue-haas-unica,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  frantiskovylazne: {
    header: {
      bg: '#c44264',
      fontSize: '20px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#c44264',
      newMessagesCountBg: '#272727',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#a7813b',
      text: '#fff',
      button: {
        bg: '#a7813b',
        bgHover: 'rgba(167, 129, 59, 0.75)',
        border: 'none',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#a7813b',
        text: '#a7813b',
        bgHover: '#a7813b',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#EFF0F1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#EFF0F1',
      text: '#272727',
      sendIcon: '#c44264'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  moneta: {
    header: {
      bg: '#18ae41',
      fontSize: '20px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#18ae41',
      shadow: '0px 0px 2px 10px rgba(24,174,65,0.20), 0px 6px 20px 1px rgba(24,174,65,0.3)',
      margin: 30,
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#18ae41',
      text: '#fff',
      button: {
        bg: 'rgba(24, 174, 65, 0.16)',
        bgHover: 'rgba(24, 174, 65, 0.85)',
        border: 'none',
        text: '#18ae41',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#18ae41',
        text: '#18ae41',
        bgHover: '#18ae41',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#EFF0F1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#EFF0F1',
      text: '#272727',
      sendIcon: '#18ae41'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  neptun: {
    header: {
      bg: '#fff',
      fontSize: '20px',
      text: '#f15a22',
      padding: '0px',
      logoOnly: true
    },
    launcher: {
      bg: '#f15a22',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#065b91',
      text: '#fff',
      button: {
        bg: '#f15a22',
        bgHover: 'rgba(241, 90, 34, 0.2)',
        border: '1px solid #f15a22',
        text: '#fff',
        textHover: '#f15a22'
      },
      qr: {
        borderColor: '#f15a22',
        text: '#f15a22',
        bgHover: '#f15a22',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#3E2A08',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: defaultFont
  },
  pigu: {
    header: {
      bg: '#4e8cff',
      text: '#fff',
      fontSize: '20px',
      padding: '0px'
    },
    launcher: {
      bg: '#4e8cff',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#1f70d9',
      text: '#fff',
      button: {
        bg: '#1f70d9',
        bgHover: 'rgba(31, 112, 217, 0.2)',
        border: '1px solid #1f70d9',
        text: '#fff',
        textHover: '#1f70d9'
      },
      qr: {
        borderColor: '#1f70d9',
        text: '#1f70d9',
        bgHover: '#1f70d9',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#3E2A08',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  sporttown: {
    header: {
      bg: '#231f20',
      fontSize: '20px',
      text: '#fff',
      padding: '0px'
    },
    launcher: {
      bg: '#F7A941',
      newMessagesCountBg: '#231f20',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#231f20',
      text: '#fff',
      button: {
        bg: '#231f20',
        bgHover: 'rgba(35, 31, 32, 0.2)',
        border: '1px solid #231f20',
        text: '#fff',
        textHover: '#231f20'
      },
      qr: {
        borderColor: '#f7931d',
        text: '#f7931d',
        bgHover: '#f7931d',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#3E2A08',
      sendIcon: '#f7931d'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: defaultFont
  },
  valachy: {
    header: {
      bg: '#A57F3E',
      fontSize: '15px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#F5BB4B',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.4,
      paddingText: '10px',
      paddingStruct: '10px',
      width: '100%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#fff',
      text: '#A57F3E',
      button: {
        bg: '#F5BB4B',
        bgHover: '#A57F3E',
        border: 'none',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#A57F3E',
        text: '#A57F3E',
        bgHover: '#A57F3E',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '10px',
      text: '#3E2A08',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F4F7F9',
      text: '#3E2A08',
      sendIcon: '#A57F3E'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: defaultFont
  },
  europcar: {
    header: {
      bg: '#009900',
      fontSize: '20px',
      text: '#ffffff',
      padding: '0px',
      logoOnly: true
    },
    launcher: {
      bg: '#009900',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#009900',
      text: '#fff',
      button: {
        bg: 'rgba(0, 153, 0, 0.2)',
        bgHover: '#009900',
        border: '1px solid #009900',
        text: '#009900',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#009900',
        text: '#009900',
        bgHover: '#009900',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(0, 153, 0, 0.2)',
        text: '#009900',
        border: '#009900'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Nunito Sans", sans-serif'
  },
  oveckarna: {
    header: {
      bg: '#4e9d6d',
      fontSize: '20px',
      text: '#ffffff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: '#4e9d6d',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#4E9D6D',
      text: '#fff',
      button: {
        bg: '#4E9D6D',
        bgHover: '#3D7B55',
        border: '1px solid #4E9D6D',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#4E9D6D',
        text: '#4E9D6D',
        bgHover: '#4E9D6D',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(78,157,109, 0.2)',
        text: '#4E9D6D',
        border: '#4E9D6D'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  grizly: {
    header: {
      bg: '#EB5B2C',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: '#10684d',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#EB5B2C',
      text: '#fff',
      button: {
        bg: '#10684d',
        bgHover: '#004c2d',
        border: '1px solid #10684d',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#10684d',
        text: '#10684d',
        bgHover: '#10684d',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(78,157,109, 0.2)',
        text: '#10684d',
        border: '#10684d'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  mobinterier: {
    header: {
      bg: '#1a0',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: false
    },
    launcher: {
      bg: '#1a0',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#1a0',
      text: '#fff',
      button: {
        bg: '#1a0',
        bgHover: '#090',
        border: '1px solid #1a0',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#1a0',
        text: '#1a0',
        bgHover: '#1a0',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(78,157,109, 0.2)',
        text: '#1a0',
        border: '#1a0'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  links: {
    header: {
      bg: '#0092c1',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: '#0092c1',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#e68d00',
      text: '#fff',
      button: {
        bg: '#0093c1',
        bgHover: '#006c86',
        border: '1px solid #0092c1',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#0092c1',
        text: '#0092c1',
        bgHover: '#0092c1',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(0, 146, 193, 0.2)',
        text: '#0092c1',
        border: '#0092c1'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  astratex: {
    header: {
      bg: '#2a2a2a',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: false
    },
    launcher: {
      bg: '#2a2a2a',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#2a2a2a',
      text: '#fff',
      button: {
        bg: '#2a2a2a',
        bgHover: '#636363',
        border: '1px solid #2a2a2a',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#2a2a2a',
        text: '#2a2a2a',
        bgHover: '#2a2a2a',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(0, 146, 193, 0.2)',
        text: '#2a2a2a',
        border: '#2a2a2a'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif'
  },
  sejurexotic: {
    header: {
      bg: '#3fcba0',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: false
    },
    launcher: {
      bg: '#3fcba0',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#3fcba0',
      text: '#fff',
      button: {
        bg: 'rgb(63, 203, 160, 0.15)',
        bgHover: 'rgb(63, 203, 160, 0.85)',
        border: '0.5px solid #3fcba0',
        text: '#3fcba0',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#3fcba0',
        text: '#3fcba0',
        bgHover: '#3fcba0',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(0, 146, 193, 0.2)',
        text: '#3fcba0',
        border: '#3fcba0'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'HK Grotesk, sans-serif'
  },
  alpinepro: {
    header: {
      bg: '#000',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: false
    },
    launcher: {
      bg: '#000',
      animation: true,
      marginBottom: '23px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      button: {
        bg: '#000',
        bgHover: '#4D4D4D',
        border: '1px solid #FFF',
        text: '#FFF',
        textHover: '#FFF'
      },
      qr: {
        borderColor: '#000',
        text: '#000',
        bgHover: '#000',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#4D4D4D',
      marginRight: '25px',
      text: '#FFF',
      file: {
        bg: '#F1F1F1',
        text: '#000',
        border: '#000'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Open Sans,Helvetica,Myriad Pro,Arial,sans-serif'
  },
  textilomanie: {
    header: {
      bg: '#16b6b8',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: '#16b6b8',
      newMessagesCountBg: '#272727',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#16b6b8',
      text: '#fff',
      button: {
        bg: '#16b6b8',
        bgHover: 'rgba(22, 182, 184, 0.75)',
        border: 'none',
        text: '#000',
        textHover: '#000'
      },
      qr: {
        borderColor: '#16b6b8',
        text: '#16b6b8',
        bgHover: '#16b6b8',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(22, 182, 184, 0.15)',
        text: '#e74c3c',
        border: '#e74c3c'
      }
    },
    receivedMessage: {
      bg: '#EFF0F1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#EFF0F1',
      text: '#272727',
      sendIcon: '#c44264'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Roboto,"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  beosport: {
    header: {
      bg: '#000',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: '#000',
      animation: true,
      marginBottom: '23px'
    },
    messageBubble: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      button: {
        bg: '#000',
        bgHover: '#4D4D4D',
        border: '1px solid #FFF',
        text: '#FFF',
        textHover: '#FFF'
      },
      qr: {
        borderColor: '#000',
        text: '#000',
        bgHover: '#000',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#4D4D4D',
      marginRight: '25px',
      text: '#FFF',
      file: {
        bg: '#F1F1F1',
        text: '#000',
        border: '#000'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Futura PT", sans-serif'
  },
  supportbox: {
    header: {
      bg: '#03c338',
      fontSize: '20px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#03c338',
      shadow: '0px 0px 2px 10px rgba(24,174,65,0.20), 0px 6px 20px 1px rgba(24,174,65,0.3)',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#377ebe',
      text: '#fff',
      button: {
        bg: '#377ebe',
        bgHover: 'rgba(55, 126, 190, 0.85)',
        border: 'none',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#377ebe',
        text: '#377ebe',
        bgHover: '#377ebe',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#377ebe',
        border: '#377ebe'
      }
    },
    receivedMessage: {
      bg: '#EFF0F1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#EFF0F1',
      text: '#272727',
      sendIcon: '#18ae41'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  queens: {
    header: {
      bg: '#84DC86',
      fontSize: '15px',
      text: '#fff',
      padding: '0px',
      logoOnly: true
    },
    launcher: {
      bg: '#84DC86',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.4,
      paddingText: '10px',
      paddingStruct: '10px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#84DC86',
      text: '#fff',
      button: {
        bg: '#84DC86',
        bgHover: 'rgb(11, 168, 40,0.85)',
        border: '1px solid #84DC86',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#84DC86',
        text: '#84DC86',
        bgHover: '#84DC86',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#84DC86'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Proxima Nova, Helvetica, Arial, sans-serif'
  },
  sportega: {
    header: {
      bg: '#080e31',
      fontSize: '15px',
      text: '#fff',
      padding: '0px',
      logoOnly: true
    },
    launcher: {
      bg: '#080e31',
      animation: true
    },
    messageBubble: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: 1.4,
      paddingText: '10px',
      paddingStruct: '10px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#080e31',
      text: '#fff',
      button: {
        bg: '#080e31',
        bgHover: 'rgba(8, 14, 49, 0.8)',
        border: '1px solid #080e31',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#080e31',
        text: '#080e31',
        bgHover: '#080e31',
        borderColorHover: '#fff',
        textHover: '#FFF'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#080e31'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Montserrat,sans-serif'
  },
  slickstyle: {
    header: {
      bg: 'linear-gradient(to right, black 50%,#434343 100%)',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: 'linear-gradient(to right, black 0%,#434343 100%)',
      animation: true,
      marginBottom: '23px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#000',
      text: '#fff',
      button: {
        bg: '#000',
        bgHover: '#4D4D4D',
        border: '1px solid #FFF',
        text: '#FFF',
        textHover: '#FFF'
      },
      qr: {
        borderColor: '#000',
        text: '#000',
        bgHover: '#000',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#000',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'futura-pt,sans-serif'
  },
  norskamoda: {
    header: {
      bg: '#002e51',
      fontSize: '20px',
      text: '#fff',
      padding: '6px',
      logoOnly: false
    },
    launcher: {
      bg: '#002e51',
      newMessagesCountBg: '#272727',
      animation: true
    },
    messageBubble: {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#002e51',
      text: '#fff',
      button: {
        bg: '#002e51',
        bgHover: 'rgba(0, 46, 81,0.8)',
        border: 'none',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#002e51',
        text: '#002e51',
        bgHover: '#002e51',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(22, 182, 184, 0.15)',
        text: '#e74c3c',
        border: '#e74c3c'
      }
    },
    receivedMessage: {
      bg: '#EFF0F1',
      marginRight: '25px',
      text: 'rgb(46, 46, 46)',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#EFF0F1',
      text: '#272727',
      sendIcon: '#c44264'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Jost", sans-serif'
  },
  multisport: {
    header: {
      bg: '#3642AE',
      fontSize: '20px',
      text: '#fff',
      padding: '6px',
      logoOnly: false
    },
    launcher: {
      bg: '#3642AE',
      newMessagesCountBg: '#272727',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#3642AE',
      text: '#fff',
      button: {
        bg: '#3642AE',
        bgHover: 'rgba(54,66,174,0.8)',
        border: 'none',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#3642AE',
        text: '#3642AE',
        bgHover: '#3642AE',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(22, 182, 184, 0.15)',
        text: '#e74c3c',
        border: '#e74c3c'
      }
    },
    receivedMessage: {
      bg: '#EFF0F1',
      marginRight: '25px',
      text: 'rgb(46, 46, 46)',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#EFF0F1',
      text: '#272727',
      sendIcon: '#c44264'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Ubuntu",sans-serif'
  },
  exasoft: {
    header: {
      bg: '#2576d0',
      fontSize: '20px',
      text: '#fff',
      padding: '6px',
      logoOnly: false
    },
    launcher: {
      bg: '#2576d0',
      newMessagesCountBg: '#272727',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#2576d0',
      text: '#fff',
      button: {
        bg: '#2576d0',
        bgHover: '#fff',
        border: '1px solid #2576d0',
        text: '#fff',
        textHover: '#2576d0'
      },
      qr: {
        borderColor: '#2576d0',
        text: '#2576d0',
        bgHover: '#2576d0',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(22, 182, 184, 0.15)',
        text: '#e74c3c',
        border: '#e74c3c'
      }
    },
    receivedMessage: {
      bg: '#EFF0F1',
      marginRight: '25px',
      text: 'rgb(46, 46, 46)',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#EFF0F1',
      text: '#272727',
      sendIcon: '#c44264'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'verdana, Segoe UI, Myriad Set Pro'
  },
  bagind: {
    header: {
      bg: '#fd9c04',
      fontSize: '20px',
      text: '#fff',
      padding: '6px',
      logoOnly: false
    },
    launcher: {
      bg: '#fd9c04',
      newMessagesCountBg: '#272727',
      animation: true,
      fontWeight: 400
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: 'url(https://amio-assets.s3.eu-central-1.amazonaws.com/bagind_bg.png) center -10px/200% auto repeat'
    },
    sentMessage: {
      wrapperBg: 'transparent',
      bg: '#000',
      text: '#fff',
      button: {
        bg: '#000',
        bgHover: '#fd9c04',
        border: 'null',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#000',
        text: '#000',
        bgHover: '#000',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(22, 182, 184, 0.15)',
        text: '#e74c3c',
        border: '#e74c3c'
      }
    },
    receivedMessage: {
      bg: 'rgba( 239, 240, 241, 0.25 )',
      marginRight: '25px',
      text: '#000',
      opacity: '80%',
      backdropFilter: 'blur( 4px )',
      boxShadow: '0 8px 20px 0 rgba( 31, 38, 135, 0.27 )',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: 'transparent',
      bg: '#EFF0F1',
      text: '#000',
      sendIcon: '#c44264'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Scto Grotesk A Medium,Helvetica,Arial,sans-serif'
  },
  hiper: {
    header: {
      bg: '#ff8c40',
      fontSize: '20px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#ff8c40',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#ff8c40',
      text: '#fff',
      button: {
        bg: '#a7c700',
        bgHover: '#809803',
        border: 'none',
        text: '#FFF',
        textHover: '#FFF'
      },
      qr: {
        borderColor: '#a7c700',
        text: '#a7c700',
        bgHover: '#a7c700',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7c700',
        border: '#a7c700'
      }
    },
    receivedMessage: {
      bg: '#ffd8be',
      marginRight: '25px',
      text: '#000',
      file: {
        bg: '#EFF0F1',
        text: '#000',
        border: '#000'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#EFF0F1',
      text: '#272727',
      sendIcon: '#a7c700'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  textilomanieNew: {
    header: {
      bg: '#16b6b8',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: false
    },
    launcher: {
      bg: '#16b6b8',
      newMessagesCountBg: '#272727',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#16b6b8',
      text: '#fff',
      button: {
        bg: '#16b6b8',
        bgHover: 'rgba(22, 182, 184, 0.75)',
        border: 'none',
        text: '#000',
        textHover: '#000'
      },
      qr: {
        borderColor: '#16b6b8',
        text: '#16b6b8',
        bgHover: '#16b6b8',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(22, 182, 184, 0.15)',
        text: '#e74c3c',
        border: '#e74c3c'
      }
    },
    receivedMessage: {
      bg: '#EFF0F1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#EFF0F1',
      text: '#272727',
      sendIcon: '#c44264'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Roboto,"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  vasky: {
    header: {
      bg: '#d7b356',
      fontSize: '20px',
      text: '#fff',
      padding: '6px',
      logoOnly: true
    },
    launcher: {
      bg: '#d7b356',
      newMessagesCountBg: '#272727',
      animation: true,
      fontWeight: 400
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: 'transparent',
      bg: '#000',
      text: '#fff',
      button: {
        bg: '#000',
        bgHover: '#d7b356',
        border: 'null',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#000',
        text: '#000',
        bgHover: '#000',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(22, 182, 184, 0.15)',
        text: '#e74c3c',
        border: '#e74c3c'
      }
    },
    receivedMessage: {
      bg: 'rgba( 239, 240, 241, 0.25 )',
      marginRight: '25px',
      text: '#000',
      opacity: '100%',
      backdropFilter: 'blur( 4px )',
      boxShadow: '0 8px 20px 0 rgba( 31, 38, 135, 0.27 )',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: 'transparent',
      bg: '#EFF0F1',
      text: '#000',
      sendIcon: '#c44264'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Graphik,Helvetica,Arial,sans-serif'
  },
  eklix: {
    header: {
      bg: '#0283CA',
      fontSize: '20px',
      text: '#ffffff',
      padding: '0px'
    },
    launcher: {
      bg: '#0283CA',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#0283CA',
      text: '#fff',
      button: {
        bg: '#00AEEF',
        bgHover: '#0283CA',
        border: '1px solid #0283CA',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#0283CA',
        text: '#0283CA',
        bgHover: '#0283CA',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: '#00AEEF',
        text: '#0283CA',
        border: '#0283CA'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Nunito Sans", sans-serif'
  },
  pilulka: {
    header: {
      bg: '#fff4f2',
      fontSize: '20px',
      text: '#e6007e',
      padding: '0px'
    },
    launcher: {
      bg: '#e6007e',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#e6007e',
      text: '#fff',
      button: {
        bg: '#e6007e',
        bgHover: '#fff4f2',
        border: '1px solid #e6007e',
        text: '#fff',
        textHover: '#e6007e'
      },
      qr: {
        borderColor: '#e6007e',
        text: '#e6007e',
        bgHover: '#e6007e',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: '#fddde2',
        text: '#e6007e',
        border: '#e6007e'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
  },
  donio: {
    header: {
      bg: '#e66d45',
      fontSize: '20px',
      text: '#141412',
      padding: '0px'
    },
    launcher: {
      bg: '#e66d45',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#e66d45',
      text: '#fff',
      button: {
        bg: '#e66d45',
        bgHover: '#ae3d17',
        border: '1px solid #e66d45',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#e66d45',
        text: '#e66d45',
        bgHover: '#e66d45',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: '#fddde2',
        text: '#e66d45',
        border: '#e66d45'
      }
    },
    receivedMessage: {
      bg: '#FFF8EE',
      marginRight: '25px',
      text: '#141412',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Rubik,sans-serif'
  },
  bazaroveregaly: {
    header: {
      bg: '#239ceb',
      fontSize: '20px',
      text: '#fff',
      padding: '0px',
      logoOnly: true
    },
    launcher: {
      bg: '#1a9d4d',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#239ceb',
      text: '#fff',
      button: {
        bg: '#239ceb',
        bgHover: '#1997e5',
        border: '1px solid #239ceb',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#239ceb',
        text: '#239ceb',
        bgHover: '#239ceb',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: '#fddde2',
        text: '#e66d45',
        border: '#e66d45'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Poppins,sans-serif'
  },
  tipli: {
    header: {
      bg: 'linear-gradient(135deg,#f29865 10%,#de5c13 80%)',
      fontSize: '20px',
      text: '#fff',
      padding: '0px',
      logoOnly: true
    },
    launcher: {
      bg: 'linear-gradient(135deg,#f29865 10%,#de5c13 80%)',
      newMessagesCountBg: '#065b91',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#f29865',
      text: '#fff',
      button: {
        bg: '#ef7b3b',
        bgHover: '#e45d13',
        border: '1px solid #ef7b3b',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#ef7b3b',
        text: '#ef7b3b',
        bgHover: '#ef7b3b',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: '#fddde2',
        text: '#e66d45',
        border: '#e66d45'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#272727',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'sans-serif'
  },
  piguWhite: {
    header: {
      bg: '#4e8cff',
      text: '#fff',
      fontSize: '20px',
      padding: '0px'
    },
    launcher: {
      bg: 'linear-gradient(135deg,#fff 10%,#fff 80%)',
      bgHover: '#DDDDDD',
      shadow: '0px 1px 4px 0px rgba(0,0,0,0.16)',
      margin: 8,
      border: '1px solid #dddddd',
      animation: false,
      closeIconColor: '#000',
      height: '48px',
      width: '48px',
      iconPadding: '12px',
      newMessagesInset: 'auto -3px -3px auto',
      newMessagesFontSize: '10px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#1f70d9',
      text: '#fff',
      button: {
        bg: '#1f70d9',
        bgHover: 'rgba(31, 112, 217, 0.2)',
        border: '1px solid #1f70d9',
        text: '#fff',
        textHover: '#1f70d9'
      },
      qr: {
        borderColor: '#1f70d9',
        text: '#1f70d9',
        bgHover: '#1f70d9',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#3E2A08',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  wobyhaus: {
    header: {
      bg: '#0054a6',
      text: '#fff',
      fontSize: '20px',
      padding: '0px'
    },
    launcher: {
      bg: '#0054a6',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#0054a6',
      text: '#fff',
      button: {
        bg: '#0054a6',
        bgHover: 'rgba(31, 112, 217, 0.2)',
        border: '1px solid #0054a6',
        text: '#fff',
        textHover: '#0054a6'
      },
      qr: {
        borderColor: '#0054a6',
        text: '#0054a6',
        bgHover: '#0054a6',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#f1f1f1',
      text: '#3E2A08',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Roboto'
  },
  istyle: {
    header: {
      bg: 'rgba(0,0,0,0.88)',
      text: '#fff',
      fontSize: '20px',
      padding: '0px'
    },
    launcher: {
      bg: 'rgba(0,0,0,0.88)',
      animation: true
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#007aff',
      text: '#fff',
      button: {
        bg: '#007aff',
        bgHover: '#52a5ff',
        border: '1px solid #007aff',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#007aff',
        text: '#007aff',
        bgHover: '#007aff',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#fafafa',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#fafafa',
      text: '#3E2A08',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Helvetica'
  },
  aktin: {
    header: {
      bg: '#303233',
      fontSize: '20px',
      text: '#fff',
      padding: '6px'
    },
    launcher: {
      bg: '#303233',
      newMessagesCountBg: '#272727',
      animation: true,
      fontWeight: 400
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: 'transparent',
      bg: '#303233',
      text: '#fff',
      button: {
        bg: '#303233',
        bgHover: '#171819',
        border: 'null',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#303233',
        text: '#303233',
        bgHover: '#303233',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(22, 182, 184, 0.15)',
        text: '#e74c3c',
        border: '#e74c3c'
      }
    },
    receivedMessage: {
      bg: '#f7f7f8',
      marginRight: '25px',
      text: '#000',
      opacity: '100%',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: 'transparent',
      bg: '#EFF0F1',
      text: '#000',
      sendIcon: '#c44264'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Inter,sans-serif'
  },
  kaskady: {
    header: {
      bg: '#ad8749',
      fontSize: '20px',
      text: '#fff',
      padding: '6px'
    },
    launcher: {
      bg: '#ad8749',
      newMessagesCountBg: '#272727',
      animation: true,
      fontWeight: 400
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: 'transparent',
      bg: '#ad8749',
      text: '#fff',
      button: {
        bg: '#ad8749',
        bgHover: '#ad8749',
        border: 'null',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#ad8749',
        text: '#ad8749',
        bgHover: '#ad8749',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(22, 182, 184, 0.15)',
        text: '#e74c3c',
        border: '#e74c3c'
      }
    },
    receivedMessage: {
      bg: '#f7f7f8',
      marginRight: '25px',
      text: '#000',
      opacity: '100%',
      file: {
        bg: '#EFF0F1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: 'transparent',
      bg: '#EFF0F1',
      text: '#000',
      sendIcon: '#c44264'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Inter,sans-serif'
  },
  brainmarket: {
    header: {
      bg: '#229ceb',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: '#111111',
      animation: true,
      marginBottom: '23px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#FFCD11',
      text: '#000',
      button: {
        bg: '#FFCD11',
        bgHover: '#111111',
        border: 'none',
        text: '#000',
        textHover: '#FFF'
      },
      qr: {
        borderColor: '#111',
        text: '#111',
        bgHover: '#FFCD11',
        borderColorHover: '#FFCD11',
        textHover: '#000'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#000',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Inter", sans-serif'
  },
  factcool: {
    header: {
      bg: '#000',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: '#808080',
      animation: true,
      marginBottom: '23px',
      height: '50px',
      width: '50px',
      iconPadding: '14px',
      radius: '0px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#000',
      text: '#FFF',
      button: {
        bg: '#FFF',
        bgHover: '#000',
        border: '1px solid #000',
        text: '#000',
        textHover: '#FFF',
        radius: '0px'
      },
      qr: {
        borderColor: '#000',
        text: '#000',
        bgHover: '#000',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#000',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'factcool,sans-serif'
  },
  miret: {
    header: {
      bg: '#669540',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: '#669540',
      animation: true,
      marginBottom: '23px'
    },
    messageBubble: {
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#000',
      text: '#FFF',
      button: {
        bg: '#000',
        bgHover: '#1a1a1ad9',
        border: '1px solid #000',
        text: '#FFF',
        textHover: '#FFF'
      },
      qr: {
        borderColor: '#000',
        text: '#000',
        bgHover: '#000',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#000',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Source Sans Pro", sans-serif'
  },
  zood: {
    header: {
      bg: 'radial-gradient(circle, rgba(40,119,244,1) 0%, rgba(48,52,147,1) 90%)',
      fontSize: '20px',
      text: '#fff',
      padding: '10px',
      logoOnly: true
    },
    launcher: {
      bg: '#303493',
      animation: true,
      marginBottom: '23px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#303493',
      text: '#FFF',
      button: {
        bg: '#303493',
        bgHover: '#FFD200',
        border: '1px solid #303493',
        text: '#FFF',
        textHover: '#303493'
      },
      qr: {
        borderColor: '#303493',
        text: '#303493',
        bgHover: '#FFD200',
        borderColorHover: '#fff',
        textHover: '#303493'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#000',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Geologica, sans-serif'
  },
  horn: {
    header: {
      bg: '#c3996b',
      fontSize: '20px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#c3996b',
      animation: true,
      marginBottom: '23px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#c3996b',
      text: '#FFF',
      button: {
        bg: '#c3996b',
        bgHover: '#EFCEAB',
        text: '#FFF',
        textHover: '#000'
      },
      qr: {
        borderColor: '#c3996b',
        text: '#c3996b',
        bgHover: '#EFCEAB',
        borderColorHover: '#EFCEAB',
        textHover: '#000'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#000',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Poppins, sans-serif'
  },
  europosters: {
    header: {
      bg: '#FF6F61',
      fontSize: '20px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#FF6F61',
      animation: true,
      marginBottom: '23px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#FF6F61',
      text: '#FFF',
      button: {
        bg: '#FF6F61',
        bgHover: '#ff9d94',
        text: '#FFF',
        textHover: '#FFF',
        radius: '0px'
      },
      qr: {
        borderColor: '#FF6F61',
        text: '#FF6F61',
        bgHover: '#FF6F61',
        borderColorHover: '#FF6F61',
        textHover: '#FFF'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#000',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Lexend Deca","Montserrat",Tahoma'
  },
  ivancica: {
    header: {
      bg: '#8dc63f',
      text: '#fff',
      fontSize: '20px',
      padding: '0px'
    },
    launcher: {
      bg: '#8dc63f',
      animation: true,
      shadow: '0px 0px 10px 10px rgba(255,255,255,0.20)'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#8dc63f',
      text: '#fff',
      button: {
        bg: '#8dc63f',
        bgHover: '#5c8524',
        border: '1px solid #8dc63f',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#8dc63f',
        text: '#8dc63f',
        bgHover: '#8dc63f',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#fafafa',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#fafafa',
      text: '#3E2A08',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Open Sans"'
  },
  dripit: {
    header: {
      bg: '#483e40',
      text: '#fff',
      fontSize: '20px',
      padding: '0px'
    },
    launcher: {
      bg: '#d0942a',
      animation: true,
      shadow: '0px 0px 10px 10px rgba(255,255,255,0.20)'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#d0942a',
      text: '#fff',
      button: {
        bg: '#d0942a',
        bgHover: '#b78225',
        border: '1px solid #d0942a',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#d0942a',
        text: '#d0942a',
        bgHover: '#d0942a',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#fafafa',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#fafafa',
      text: '#3E2A08',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Roboto, sans-serif'
  },
  bezvasport: {
    header: {
      bg: '#000',
      fontSize: '20px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#E01010',
      animation: true,
      marginBottom: '23px',
      height: '50px',
      width: '50px',
      iconPadding: '14px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#000',
      text: '#FFF',
      button: {
        bg: '#FFF',
        bgHover: '#000',
        border: '1px solid #000',
        text: '#000',
        textHover: '#FFF',
        radius: '0px'
      },
      qr: {
        borderColor: '#000',
        text: '#000',
        bgHover: '#000',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#f1f1f1',
      marginRight: '25px',
      text: '#000',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'factcool,sans-serif'
  },
  mtbiker: {
    header: {
      bg: '#f7941e',
      text: '#fff',
      fontSize: '20px',
      padding: '0px'
    },
    launcher: {
      bg: '#f7941e',
      animation: true,
      shadow: '0px 0px 10px 10px rgba(255,255,255,0.20)'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#f7941e',
      text: '#fff',
      button: {
        bg: '#f7941e',
        bgHover: '#e78108',
        border: '1px solid #f7941e',
        text: '#fff',
        textHover: '#fff'
      },
      qr: {
        borderColor: '#f7941e',
        text: '#f7941e',
        bgHover: '#f7941e',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#fafafa',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#fafafa',
      text: '#3E2A08',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: 'Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji'
  },
  alexandria: {
    header: {
      bg: '#06192AE6',
      text: '#fff',
      fontSize: '20px',
      padding: '0px'
    },
    launcher: {
      bg: '#06192AE6',
      animation: true,
      shadow: '0px 0px 10px 10px rgba(255,255,255,0.20)'
    },
    messageBubble: {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#d3b36e',
      text: '#0e273f',
      button: {
        bg: '#d3b36e',
        bgHover: '#0e273f',
        text: '#0e273f',
        textHover: '#d3b36e'
      },
      qr: {
        borderColor: '#d3b36e',
        text: '#0e273f',
        bgHover: '#0e273f',
        borderColorHover: '#0e273f',
        textHover: '#d3b36e'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#18ae41',
        border: '#18ae41'
      }
    },
    receivedMessage: {
      bg: '#fafafa',
      marginRight: '25px',
      text: '#272727',
      file: {
        bg: '#f1f1f1',
        text: '#272727',
        border: '#272727'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#fafafa',
      text: '#3E2A08',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"Roboto Condensed", sans-serif'
  },
  irisimo: {
    header: {
      bg: '#303030',
      fontSize: '30px',
      text: '#fff',
      padding: '10px'
    },
    launcher: {
      bg: '#303030',
      animation: true,
      marginBottom: '23px'
    },
    messageBubble: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.2,
      paddingText: '10px 20px',
      paddingStruct: '20px 20px 15px 20px',
      width: '85%'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#303030',
      button: {
        bg: '#FFF',
        bgHover: '#303030',
        border: '1px solid #FFF',
        text: '#303030',
        textHover: '#FFF'
      },
      qr: {
        borderColor: '#303030',
        text: '#303030',
        bgHover: '#303030',
        borderColorHover: '#fff',
        textHover: '#fff'
      },
      file: {
        bg: 'rgba(24, 174, 65, 0.15)',
        text: '#a7813b',
        border: '#a7813b'
      }
    },
    receivedMessage: {
      bg: '#4D4D4D',
      marginRight: '25px',
      text: '#FFF',
      file: {
        bg: '#F1F1F1',
        text: '#000',
        border: '#000'
      }
    },
    userInput: {
      wrapperBg: '#fff',
      bg: '#F1F1F1',
      text: '#000',
      sendIcon: '#000'
    },
    statusInfo: {
      bg: '#4bcc6f',
      text: '#fff'
    },
    font: '"GillSansMTPro-Medium", "Gill Sans", Corbel, Arial, sans-serif'
  }
}
