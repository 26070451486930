<template>
  <div class='sc-message--file' :style="fileStyle">
    <div class='sc-message--file-name'>
      <icon-file :color="fileStyle.color" />
      <a v-if="data.url" :href="data.url" target='_blank'>{{fileName}}</a>
      <span v-else>{{fileName}}</span>
    </div>
  </div>
</template>

<script>
import IconFile from '../icons/icons/IconFile'

export default {
  components: {
    IconFile
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    messageColors: {
      type: Object,
      required: true
    },
    direction: {
      type: String,
      required: true
    }
  },

  computed: {
    fileName () {
      if (this.data.file) return this.data.file.name

      if (this.data.url) {
        const urlParts = this.data.url.split('/')
        return urlParts[urlParts.length - 1]
      }

      return ''
    },
    fileStyle () {
      const colors = this.direction === 'sent' ? this.messageColors.sentMessage.file : this.messageColors.receivedMessage.file

      return {
        color: colors.text,
        backgroundColor: colors.bg,
        borderColor: colors.border,
        borderStyle: 'solid',
        borderWidth: '1px'
      }
    }
  }
}
</script>

<style scoped>
.sc-message--file {
  -webkit-font-smoothing: subpixel-antialiased
}

.sc-message--content.sent .sc-message--file {
  overflow-wrap: anywhere;
}

.sc-message--file-text {
  padding: 17px 20px;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased
}

.sc-message--file-name {
  display: flex;
  align-items: center;
  margin: 6px 10px;
  font-size: smaller;
  text-align: center;
  border-radius: 6px;
  color: inherit;
}

.sc-message--file-name a {
  margin-left: 2px;
  text-decoration: underline;
  color: inherit;
}

.sc-message--content.received .sc-message--file {
  margin-right: 40px;
}

</style>
