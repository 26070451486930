<template>
  <div>
    <button class="sc-user-input--file-icon-wrapper" type="button">
      <label for="file-input" class="file-input-button">
        <icon-file :title="title" :color="color" />
      </label>

      <!-- accepted file types must be in sync with amio-chat file-upload.socket-io.schema.json -->
      <input
        type="file"
        id="file-input"
        accept="
          application/msword,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/vnd.ms-excel,
          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
          application/vnd.apple.pages,
          application/x-iwork-pages-sffpages,
          application/vnd.apple.numbers,
          application/x-iwork-numbers-sffnumbers,
          application/pdf,
          image/gif,
          image/jpeg,
          image/png,
          image/tiff,
          image/webp,
          text/csv,
          text/plain,
          audio/aac,
          audio/amr,
          audio/midi,
          audio/mp4,
          audio/mpeg,
          audio/ogg,
          audio/x-m4a,
          audio/x-wav,
          video/3gpp,
          video/mp4,
          video/mpeg,
          video/ogg,
          video/quicktime,
          video/webm,
          video/x-m4v,
          video/x-msvideo
        "
        @change="_handleChange"
        @click="_handleClick"
      />
    </button>
  </div>
</template>

<script>
import IconFile from './icons/IconFile'

export default {
  components: {
    IconFile
  },

  props: {
    onChange: {
      type: Function,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    title: {
      type: String
    }
  },
  methods: {
    _handleClick (e) {
      e.target.value = null
    },
    _handleChange (e) {
      this.onChange(e.target.files[0])
    }
  }
}
</script>

<style scoped>

.file-input-button {
  cursor: pointer;
  display: block;
  padding: 0;
}

.sc-user-input--file-icon-wrapper {
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: none;
}

.sc-user-input--file-icon {
  height: 20px;
  width: 20px;
  padding-top: 5px;
  align-self: center;
  outline: none;
}

.sc-user-input--file-icon:hover path {
  filter: contrast(15%);
}

input[type=file] {
    display:none;
}
</style>
