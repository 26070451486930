import { amioChat } from 'amio-chat-sdk-web'
import OpusMediaRecorder from 'opus-media-recorder'
import Worker from 'opus-media-recorder/encoderWorker.js'
// Uses file-loader that returns URL path
import OggOpusWasm from 'opus-media-recorder/OggOpusEncoder.wasm'
import WebMOpusWasm from 'opus-media-recorder/WebMOpusEncoder.wasm'

// Non-standard options for opus media recorder
const workerOptions = {
  encoderWorkerFactory: _ => new Worker(),
  OggOpusEncoderWasmPath: OggOpusWasm,
  WebMOpusEncoderWasmPath: WebMOpusWasm
}

const MIME_TYPE_WEBM = 'audio/webm'

class VoiceService {
  constructor (navigator) {
    this.isMicrophoneAccessDenied = false
    this.mediaDevices = navigator.mediaDevices
    this.stream = null
    this.recorder = null
    this.audioRecording = false
    this.isSoundDetected = false
    this.voiceUploadLoading = false
    this.lastSoundCheck = 0
    this.silencePeriod = 4000
    this.silenceStart = 0
    this.recordingInterval = null
    this.soundData = []
    this.isDictationReady = false
    this.isPolyfillUsed = false

    if (!MediaRecorder.isTypeSupported(MIME_TYPE_WEBM)) {
      // polyfill MediaRecorder in case the browser (notably Safari) does not support webm
      window.MediaRecorder = OpusMediaRecorder
      this.isPolyfillUsed = true
    }

    this.checkMicrophoneAccess(navigator)
  }

  checkMicrophoneAccess (navigator) {
    navigator.permissions.query({ name: 'microphone' }).then((result) => {
      this.isMicrophoneAccessDenied = result.state === 'denied'
    })
  }

  async _setRecorder () {
    this.stream = await this.mediaDevices.getUserMedia({ audio: true })

    this.recorder = this.isPolyfillUsed
      ? new MediaRecorder(this.stream, { mimeType: MIME_TYPE_WEBM }, workerOptions)
      : new MediaRecorder(this.stream, { mimeType: MIME_TYPE_WEBM })
    this.recorder.onstop = () => clearInterval(this.recordingInterval)
    this.recorder.ondataavailable = e => this._handleRecordedBlob(e.data)
  }

  _handleRecordedBlob (data) {
    if (!this.audioRecording && this.recorder?.state === 'recording') {
      this.recorder.stop()
    }

    this._onVoiceRecorded(data)
  }

  async _onVoiceRecorded (data) {
    await this._sendDictationChunk(data)
    if (!this.audioRecording) await this._resetRecorder()
  }

  async _resetRecorder () {
    try {
      await amioChat.dictation.stop()
    } catch (err) {
      /* eslint-disable no-console */
      console.error(err)
    }
    this.voiceUploadLoading = false
    this.isDictationReady = false
    this.stream.getTracks()[0].stop()
    this.recorder = null
  }

  _initSoundAnalyzer () {
    const ctx = new AudioContext()
    const analyser = ctx.createAnalyser()
    const streamNode = ctx.createMediaStreamSource(this.stream)
    streamNode.connect(analyser)
    // decibels of silence (expecting basic noice of environment)
    analyser.minDecibels = -65

    this.soundData = new Uint8Array(analyser.frequencyBinCount)
    this.silenceStart = +new Date()
    this._analyzeSound(analyser)
  }

  _analyzeSound (analyser) {
    if (!this.audioRecording) return

    requestAnimationFrame(() => this._analyzeSound(analyser))
    analyser.getByteFrequencyData(this.soundData)

    const soundBits = this.soundData.filter(bit => !!bit).length
    const millisecondsNow = +new Date()

    // for stopping of recording if silence
    if (soundBits) {
      this.silenceStart = millisecondsNow
    } else if (millisecondsNow - this.silenceStart > this.silencePeriod) {
      this.handleAudioRecording()
    }

    // for pulsing animation
    const animationTime = 500
    if ((millisecondsNow - this.lastSoundCheck) > animationTime) {
      this.isSoundDetected = soundBits > 0
      this.lastSoundCheck = millisecondsNow
    }
  }

  async handleAudioRecording () {
    if (this.audioRecording) {
      this.audioRecording = false
      return
    }

    await this._initRecorder()
    if (this.isMicrophoneAccessDenied) return

    this._initRecording()
    this._initSoundAnalyzer()
  }

  async _initRecorder () {
    try {
      if (!this.recorder) await this._setRecorder()
      this.recorder.start()
    } catch {
      console.error('microphone access denied')
      this.isMicrophoneAccessDenied = true
    }
  }

  _initRecording () {
    try {
      amioChat.dictation.start(this.recorder.mimeType).then(response => {
        if (!response.code) this.isDictationReady = true
      })
    } catch (err) {
      /* eslint-disable no-console */
      console.error(err)
      this.voiceUploadLoading = false
      this.audioRecording = false
      return
    }
    this.voiceUploadLoading = true
    this.audioRecording = true
    this._setRecordingInterval()
  }

  async _setRecordingInterval () {
    if (!this.audioRecording) return

    this.recordingInterval = setInterval(() => this._requestRecorderData(), 200)
  }

  _requestRecorderData () {
    if (this.isDictationReady) this.recorder.requestData()
  }

  async _sendDictationChunk (data) {
    const buffer = await data.arrayBuffer()
    try {
      await amioChat.dictation.sendData(buffer)
    } catch (err) {
      /* eslint-disable no-console */
      console.error(err)
      this.voiceUploadLoading = false
      this.audioRecording = false
      this.isDictationReady = false
    }
  }
}

export default VoiceService
