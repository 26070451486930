<template>
  <div class='sc-message--struct' :style="messageColors.received">
   <p v-if="data.title" class="sc-message--struct-title">{{data.title}}</p>
   <p v-if="data.text" class="sc-message--struct-content">{{data.text}}</p>
   <div v-if="data.buttons" class="sc-message--struct-buttons">
     <template v-for="(button, idx) in data.buttons">
       <button v-if="['postback', 'callback'].includes(button.type)"
               class="button"
               :key="`button-${idx}`"
               :style="messageColors.sent"
               @click="$emit('buttonPressed', button)"
       >
         <span class="button-text">{{button.title}}</span>
       </button>
       <a v-if="button.type === 'url'" :href="button.payload" target="_blank" :key="`button-${idx}`" class="button" :style="messageColors.sent" @click="$emit('buttonPressed', button)"><span class="button-text">{{button.title}}</span></a>
       <a v-if="button.type === 'phone'" :href="`tel:${button.payload}`" target="_parent" :key="`button-${idx}`" class="button" :style="messageColors.sent" @click="$emit('buttonPressed', button)"><span class="button-text">{{button.title}}</span></a>
     </template>
   </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    messageColors: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.sc-message--struct {
  position: relative;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;

  p {
    margin: 0 0 10px  0 ;
  }

  &-content {
    word-break: break-word;
  }
}

.sc-message--struct-title {
  font-weight: 500;
}

.sc-message--struct-buttons {
  display: flex;
  flex-direction: column;
}

.button {
  color: var(--button-text) !important;
  border: none;
  border-radius: var(--button-radius) !important;
  width: 100%;
  margin: 2px 0;
  font-size: 12px;
  font-family: inherit;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  min-height: 30px;
  min-width: 90px;
}

.button-text {
  padding: 0 5px;
  line-height: 14px;
}

a {
  color: var(--button-text) !important;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

.button:hover {
  color: var(--button-text-hover) !important;
  background: var(--button-bg-hover) !important;
  cursor: pointer;
}

.sc-message--content.sent .sc-message--struct {
  overflow-wrap: anywhere;
}

</style>
